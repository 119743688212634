import React, { Component } from "react";
import { connect } from "react-redux";
import {
  apiAction,
  updateControllerDetails,
  updateGatewayControllers,
} from "redux/actions/index";
import { bindActionCreators } from "redux";
import {
  Card,
  CardBody,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";
import { withTranslation } from "react-i18next";
import { AuthorizationContext } from "contexts/AuthorizationContext";
import IconLabel from "components/IconLabel";
import { TooltipButton } from "components/Tooltip";
import "./style.css";

class DeviceInfo extends Component {
  static contextType = AuthorizationContext;
  constructor(props) {
    super(props);

    this.friendlyName =
      props.controllers.byId[this.props.deviceGuid].friendlyName;
    this.cabinetSerial =
      props.controllers.byId[this.props.deviceGuid].cabinetSerial;
    this.state = {
      friendlyName: this.friendlyName,
      friendlyNameChanged: false,
      isLoading: false,
      cabinetSerial: this.cabinetSerial,
      cabinetSerialChanged: false,
    };
  }

  onInputChange = (e) => {
    this.setState({ 
      friendlyName: e.target.value,
      friendlyNameChanged: e.target.value !== this.friendlyName
    });
  };

  onInputCabinetSerialChange = (e) => {
    this.setState({
      cabinetSerial: e.target.value,
      cabinetSerialChanged: true,
    });
  };

  onSaveChanges = () => {
    this.props.apiAction(
      api.updateDevice,
      [
        this.props.deviceGuid,
        this.state.friendlyName,
        this.state.cabinetSerial,
      ],
      updateControllerDetails
    );

    let deviceInfo = {
      gatewayGuid: this.props.gatewayInfo.gatewayGuid,
      deviceGuid: this.props.deviceGuid,
      friendlyName: this.state.friendlyName,
      cabinetSerial: this.state.cabinetSerial,
    };

    this.props.updateGatewayControllers(deviceInfo);
  };

  onForgetChanges = () => {
    this.setState({
      friendlyName: this.friendlyName,
      friendlyNameChanged: false,
      cabinetSerial: this.cabinetSerial,
      cabinetSerialChanged: false,
    });
  };

  render() {
    const controller = this.props.controllers.byId[this.props.deviceGuid];
    let model, family, release, map;
    try {
      model = utils.getModelFromMFVE(controller.model);
      family = parseInt(controller.model.split("_")[1].split("-")[0], 16);
      let rel = parseInt(
        controller.model.split("_")[1].split("-")[1].split("-")[0],
        16
      );
      release = rel / 10 + (rel % 10 === 0 ? ".0" : "");
      map = parseInt(controller.model.split("_")[1].split("-")[2], 16);
    } catch (e) {}

    const address = controller.address;

    const customer = controller.customer.name;
    const site = controller.site.name;
    const siteGuid = controller.site.siteGuid;
    const gateway = controller.gateway.name;
    const gatewayAddress = controller.gateway.macAddress;

    const gatewayInfo = {
      site: site,
      siteGuid: siteGuid,
      customer: customer,
      name: gateway,
      gatewayGuid: controller.gateway.gatewayGuid,
      macAddress: gatewayAddress,
      devices: this.props.controllers,
    };

    const siteDetails = this.props.sites[siteGuid];

    const siteInfo = {
      city: siteDetails.city,
      connectivitytype: siteDetails.connectivitytype,
      country: siteDetails.country,
      latitude: siteDetails.latitude,
      longitude: siteDetails.longitude,
      name: siteDetails.name,
      siteGuid: siteDetails.siteGuid,
    };

    const inputChanged =
      this.state.friendlyNameChanged || this.state.cabinetSerialChanged;
    const readOnly = !this.context.hasWritePermission(
      controller.gateway.gatewayGuid
    );

    const fieldMinWidth = "85px";

    return (
      <Card style={{ marginTop: "20px" }}>
        <CardBody>
          {this.state.isLoading ? <Loader /> : null}
          <div className={`model-info`}>
            <InputGroup className="mb-3">
              <InputGroupText id="controller-friendly-name">
                <IconLabel
                  icon={keys.ICON_CONTROLLER}
                  label={this.props.t("details.friendlyName")}
                  minWidth={fieldMinWidth}
                />
              </InputGroupText>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder={model}
                onChange={(e) => this.onInputChange(e)}
                value={this.state.friendlyName || ""}
                maxLength="50"
                className="pl-1"
              />
              <TooltipButton
                target="controller-friendly-name"
                text={this.props.t("details.friendlyName")}
              />
            </InputGroup>
            <InputGroup className="mb-3 model-serial">
              <InputGroupText id="controller-serial-id">
                <IconLabel
                  icon={keys.ICON_SERIAL_ID}
                  label={this.props.t("details.cabinetSerial")}
                  minWidth={fieldMinWidth}
                />
              </InputGroupText>
              <Input
                id="serial-id"
                name="serial-id"
                type="text"
                placeholder={this.props.t("details.cabinetSerial")}
                onChange={(e) => this.onInputCabinetSerialChange(e)}
                value={this.state.cabinetSerial || ""}
                maxLength="50"
                className="pl-1"
              />
              <TooltipButton
                target="controller-serial-id"
                text={this.props.t("details.cabinetSerial")}
              />
            </InputGroup>
          </div>
          <div
            className={`${this.props.isDongleController ? "d-none" : ""} model-info`}
          >
            <InputGroup className="mb-3">
              <Input
                placeholder={this.props.t("details.model")}
                id="model"
                name="model"
                type="text"
                value={
                  this.props.t("details.model") +
                  ": " +
                  (model !== undefined ? model : "")
                }
                readOnly
              />
            </InputGroup>
            <div className="persona-data">
              <InputGroup className="mb-3 model-data">
                <Input
                  placeholder={this.props.t("details.address")}
                  id="address"
                  name="address"
                  type="text"
                  value={
                    this.props.t("details.address") +
                    ": " +
                    (address !== undefined ? address : "")
                  }
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3 model-data">
                <Input
                  placeholder={this.props.t("details.family")}
                  id="family"
                  name="family"
                  type="text"
                  value={
                    this.props.t("details.family") +
                    ": " +
                    (family !== undefined ? family : "")
                  }
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3 model-data">
                <Input
                  placeholder={this.props.t("details.release")}
                  id="release"
                  name="release"
                  type="text"
                  value={
                    this.props.t("details.release") +
                    ": " +
                    (release !== undefined ? release : "")
                  }
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3 model-data">
                <Input
                  placeholder={this.props.t("details.mapLayout")}
                  id="map"
                  name="map"
                  type="text"
                  value={
                    this.props.t("details.mapLayout") +
                    ": " +
                    (map !== undefined ? map : "")
                  }
                  readOnly
                />
              </InputGroup>
            </div>
          </div>
          <InputGroup className="mb-3">
            <InputGroupText id="controller-site">
              <IconLabel
                icon={keys.ICON_SITE}
                label={this.props.t("details.site")}
                minWidth={fieldMinWidth}
              />
            </InputGroupText>
            <Link
              className="form-control bg-secondary text-primary text-nowrap pl-1"
              to={{
                pathname: "/std/sstgs",
                site: siteInfo,
              }}
            >
              {site}
            </Link>
            <TooltipButton
              target="controller-site"
              text={this.props.t("details.site")}
            />
          </InputGroup>
          <InputGroup
            className={`mb-3 ${
              this.props.gatewayInfo?.status === "OFF"
                ? "border border-warning"
                : ""
            }`}
          >
            <InputGroupText
              id="controller-gateway"
              className={`${
                this.props.gatewayInfo?.status === "OFF" ? "text-warning" : ""
              }`}
            >
              <IconLabel
                icon={keys.ICON_GATEWAY}
                label={<>
                  <span className="mr-1">{this.props.t("details.gateway")}</span>
                  {this.props.gatewayInfo?.status === "OFF" && keys.ICON_WARNING}
                </>}
                minWidth={fieldMinWidth}
              />
            </InputGroupText>
            <Link
              className="form-control bg-secondary text-primary text-nowrap pl-1"
              to={{
                pathname: "/std/gtw",
                gateway: gatewayInfo,
                site: siteInfo,
              }}
            >
              {gateway + " (" + gatewayAddress + ")"}
            </Link>
            <TooltipButton
              target="controller-gateway"
              text={this.props.t("details.gateway")}
            />
          </InputGroup>

          <div className="device-buttons">
            <Button
              className="mt-2 mx-1"
              color="primary"
              type="button"
              disabled={
                !inputChanged ||
                readOnly ||
                this.state.isLoading
              }
              onClick={this.onSaveChanges}
            >
              <span className="mr-2 btn-inner--icon">{keys.ICON_SAVE}</span>
              <span className="btn-inner--text">
                {this.props.t("details.save")}
              </span>
            </Button>

            <Button
              className="mt-2 mx-1"
              color="primary"
              type="button"
              disabled={!inputChanged || readOnly || this.state.isLoading}
              onClick={this.onForgetChanges}
            >
              <span className="mr-2 btn-inner--icon">{keys.ICON_UNDO}</span>
              <span className="btn-inner--text">
                {this.props.t("details.forget")}
              </span>
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    controllers: state.controllers,
    sites: state.sites.byId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
      updateControllerDetails,
      updateGatewayControllers,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(DeviceInfo)
);
