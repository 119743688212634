/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import Preferences from "components/Forms/Preferences";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindActionCreators } from "redux";
import { apiAction } from "redux/actions/index";
import RegisterNewGateway from "components/RegisterNewGateway/index";

import { SearchContainer } from "../Headers/ThinHeader";
import ProductLogo from "components/ProductLogo";
import { withTranslation } from "react-i18next";
import { mapSearchItemsToProps } from "components/Headers/ThinHeader";

import "./sidebar.css";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Container,
  Tooltip,
} from "reactstrap";

import keys from "configs/constants";
import utils from "utils";
import api from "services/backendService";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import OrganizationDropdown from "../OrganizationDropdown";
import { Redirect } from "react-router-dom";

class Sidebar extends React.Component {
  static contextType = AuthorizationContext;

  state = {
    collapseOpen: false,
    searchTerm: "",
    isPreferencesOpen: false,
    showNewGatewayModal: false,
    newMacAddress: "",
    subscriptionRequiredTooltipOpen: false,
    isDocumentsDropdownOpen: false,
    organizationsList: [],
    groups: [],
    redirectUrl: null,
  };

  subscriptionRequiredTooltipToggle = () => {
    this.setState({
      subscriptionRequiredTooltipOpen:
        !this.state.subscriptionRequiredTooltipOpen,
    });
  };

  constructor(props) {
    super(props);

    this.activeRoute.bind(this);
  }

  componentDidMount() {
    const savedActiveOrg = localStorage.getItem("activeOrganization");
    if (savedActiveOrg) {
      const activeOrganization = JSON.parse(savedActiveOrg);
      this.context.setActiveOrganization(activeOrganization);
    }
    this.updateOrganizationsList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.context.groups) !== JSON.stringify(prevState.groups)
    ) {
      this.setState({ groups: this.context.groups }, () => {
        this.updateOrganizationsList();
      });
    }
  }

  updateOrganizationsList() {
    let filteredGroups = [];

    api
      .getCustomersInitialState()
      .then((response) => {
        const customers = response.byId;
        Object.values(customers).forEach((customer) => {
          filteredGroups.push({
            guid: customer.customerGuid,
            name: customer.name,
            id: customer.groupId,
          });
        });

        let activeOrganization = this.context.activeOrganization;
        const isActiveOrgValid = filteredGroups.some(
          (group) => group.guid === activeOrganization?.guid
        );

        if (!isActiveOrgValid) {
          activeOrganization =
            filteredGroups.length > 0 ? filteredGroups[0] : null;
        }
        if (
          JSON.stringify(filteredGroups) !==
          JSON.stringify(this.state.organizationsList)
        ) {
          this.setState({ organizationsList: filteredGroups });
        }
        if (activeOrganization.guid !== this.context.activeOrganization?.guid) {
          this.updateActiveOrganization(activeOrganization);
        }
      })
      .catch((error) => {
        utils.debug(error, utils.MSG_TYPE_WARN);
      });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  menuSeparator = () => {
    return (
      <Container fluid>
        <Row className="justify-content-center">
          <div className="menu-separator" />
        </Row>
      </Container>
    );
  };

  closeModal = () => {
    this.setState({
      newMacAddress: "",
      showNewGatewayModal: false,
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, canRegisterNewGateway) => {
    const th = this;
    let showSeparator = false;
    let startMenuSidebar = this.showOrganizationDropdown();

    let countAlertsForActiveOrganization = this.props.alerts.total;

    if (!this.context.isSuperAdmin() && this.context.activeOrganization)
      countAlertsForActiveOrganization = utils.countAlertsForCustomer(
        th.props.alerts,
        this.context.activeOrganization.guid
      );

    let unlicensedGateways = this.props.gateways.filter((gw) => {
      return gw.licenses.filter((l) => l.isActive).length === 0;
    });

    return (
      <>
        {routes.map((prop, key) => {
          if (prop.menu === false) return "";
          if (th.props.onlyAdmin) {
            if (!prop.layout.includes("/ad")) {
              return;
            } else if (!utils.isUserAdmin() && !prop.superUser) {
              return;
            }
          } else {
            if (prop.layout.includes("/ad")) {
              return;
            }
          }
          showSeparator = startMenuSidebar;
          startMenuSidebar = false;

          if (prop.link) {
            return (
              <NavItem key={key} className="ml-1">
                {showSeparator ? th.menuSeparator() : null}
                <NavLink
                  to={{
                    pathname: prop.link,
                  }}
                  target="_blank"
                  tag={NavLinkRRD}
                >
                  {prop.icon}
                  {prop.name}
                </NavLink>
              </NavItem>
            );
          } else {
            const textColor =
              prop.name === "alarmLog" && countAlertsForActiveOrganization > 0
                ? "text-danger"
                : null;

            return (
              <NavItem key={key} className="ml-1">
                {showSeparator ? th.menuSeparator() : null}
                <NavLink
                  to={prop.layout + prop.path}
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i
                    {...prop.icon.props}
                    className={`${prop.icon.props.className} ${textColor ?? ""}`}
                  />
                  {th.props.t("sidebar." + prop.name)}
                  {prop.nActiveAlarms ? (
                    countAlertsForActiveOrganization > 0 ? (
                      " (" +
                      countAlertsForActiveOrganization +
                      " " +
                      th.props.t(
                        "sidebar." +
                          (countAlertsForActiveOrganization > 1
                            ? "moreActive"
                            : "oneActive")
                      ) +
                      ")"
                    ) : null
                  ) : prop.license ? (
                    unlicensedGateways.length > 0 ? (
                      <div className="item-right-icon">
                        <span>{keys.ICON_WARNING}</span>
                        <span className="ml-1">
                          {unlicensedGateways.length}
                        </span>
                      </div>
                    ) : null
                  ) : null}
                </NavLink>
              </NavItem>
            );
          }
        })}
        {th.props.onlyAdmin ? null : (
          <>
            {th.menuSeparator()}
            <NavItem
              className="ml-1 register-new-gateway"
              onClick={() =>
                canRegisterNewGateway &&
                this.setState({
                  showNewGatewayModal: !this.state.showNewGatewayModal,
                })
              }
            >
              <NavLink
                id="registerNewGateway"
                disabled={!canRegisterNewGateway}
              >
                {keys.ICON_MENU_ADD}
                {th.props.t("sidebar.registerNewGateway")}
                {!canRegisterNewGateway && (
                  <div className="item-right-icon">
                    <span>{keys.ICON_WARNING}</span>
                  </div>
                )}
              </NavLink>
              {!canRegisterNewGateway && (
                <Tooltip
                  placement="right"
                  isOpen={this.state.subscriptionRequiredTooltipOpen}
                  target="registerNewGateway"
                  toggle={this.subscriptionRequiredTooltipToggle}
                >
                  {th.props.t("sidebar.subscriptionRequired")}
                </Tooltip>
              )}
            </NavItem>
            <RegisterNewGateway
              isOpen={this.state.showNewGatewayModal}
              toggle={this.closeModal}
              value={this.state.newMacAddress}
              onChange={(e) => {
                this.setState({ newMacAddress: e.target.value });
              }}
            />
          </>
        )}
      </>
    );
  };

  handleClick = (e) => {
    if (e.currentTarget.getAttribute("value") === "logout") {
      this.props.onSignOut();
    }
  };

  returnDocLinks() {
    if (keys.SHOW_CREATIVETIM_DOC === "yes") {
      return (
        <>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          <h6 className="navbar-heading text-muted">Documentation</h6>
          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                <i className="ni ni-spaceship" />
                Getting started
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar">
                <i className="ni ni-palette" />
                Foundation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">
                <i className="ni ni-ui-04" />
                Components
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="mb-md-3" navbar>
            <NavItem className="active-pro active">
              <NavLink href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=adr-admin-sidebar">
                <i className="ni ni-spaceship" />
                Upgrade to PRO
              </NavLink>
            </NavItem>
          </Nav>
        </>
      );
    } else return "";
  }

  onInputChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  dynamicSearch = () => {
    if (this.state.searchTerm === "") return [];
    const s = this.props.searchItems.filter((item) => {
      return (
        item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
        item.extra.toLowerCase().includes(this.state.searchTerm.toLowerCase())
      );
    });
    return s;
  };

  clearSearch = () => {
    this.setState({ searchTerm: "" });
    this.toggleCollapse();
  };

  togglePreferencesModal = (status = undefined) => {
    this.setState({
      isPreferencesOpen:
        typeof status !== "boolean" ? !this.state.isPreferencesOpen : status,
    });
  };

  showOrganizationDropdown = () => {
    return (
      this.context.activeOrganization &&
      this.state.organizationsList.length > 1 &&
      !this.context.isSuperAdmin()
    );
  };

  updateActiveOrganization = (org) => {
    localStorage.setItem("activeOrganization", JSON.stringify(org));
    this.context.setActiveOrganization(org);
    this.setState({ redirectUrl: "/" });
  };

  render() {
    if (this.state.redirectUrl) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    const username = this.props.auth.user.idToken.name;
    const { routes, logo } = this.props;
    const canRegisterNewGateway = this.context.canRegisterNewGateway();

    const creativeTimDocSection = this.returnDocLinks();
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }

    const itemsFound = this.dynamicSearch();

    return (
      <>
        <Preferences
          isOpen={this.state.isPreferencesOpen}
          toggle={this.togglePreferencesModal}
          auth={this.props.auth}
          setUser={this.props.setUser}
          t={this.props.t}
        />
        <Navbar
          className="navbar-vertical fixed-left navbar-light bg-white"
          expand="md"
          id="sidenav-main"
        >
          <div className="sidebar-container">
            {/* Toggler */}
            <button
              className="navbar-toggler sidebar-menu m-0"
              type="button"
              onClick={this.toggleCollapse}
            >
              {keys.ICON_SIDEBAR_MENU}
            </button>
            {/* Brand */}
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <div className="d-none d-md-block">
                  <ProductLogo logoWidth="w-75" />
                </div>
                <div className="d-md-none">
                  <ProductLogo logoWidth="w-50" />
                </div>
              </NavbarBrand>
            ) : null}
            {/* User */}
            <Nav className="align-items-center d-md-none sidebar-user">
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <div className="ml-2 d-lg-block">
                    <span className="mb-0 small font-weight-bold">
                      {username}
                    </span>
                    <br />
                    <div className="text-center">
                      {utils.isUserAdmin() && (
                        <span className="badge bg-danger text-white">
                          SuperAdmin
                        </span>
                      )}
                    </div>
                  </div>
                </DropdownToggle>
                {this.showOrganizationDropdown() && (
                  <>
                    <span className="mr-1">{keys.ICON_CUSTOMER}</span>
                    <span className="mb-0 small font-weight-bold">
                      {this.context.activeOrganization.name}
                    </span>
                  </>
                )}
                <DropdownMenu className="dropdown-menu-arrow" end>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">
                      {this.props.t("navbar.welcome") + "!"}
                    </h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>{this.props.t("navbar.myProfile")}</span>
                  </DropdownItem>
                  {/* Preferences */}
                  <div
                    className="dropdown-item"
                    value="preferences"
                    onClick={this.togglePreferencesModal}
                  >
                    <span className="btn-inner--icon">
                      {keys.ICON_PREFERENCES}
                    </span>
                    <span className="ml-3 btn-inner--text cursor-default">
                      {this.props.t("navbar.preferences")}
                    </span>
                  </div>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div value="logout" onClick={this.handleClick.bind(this)}>
                      <i className="ni ni-user-run" />
                      <span className="ml-3 btn-inner--text">
                        {this.props.t("navbar.logout")}
                      </span>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header d-md-none ">
                <Row>
                  {logo ? (
                    <Col>
                      <NavbarBrand {...navbarBrandProps}>
                        <div className="d-md-none">
                          <ProductLogo logoWidth="w-75" noText />
                        </div>
                      </NavbarBrand>
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.toggleCollapse}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {/* Form */}
              <Form className="mt-4 mb-3 d-md-none">
                <InputGroup className="input-group-rounded input-group-merge">
                  <Input
                    aria-label="Search"
                    className="form-control-rounded form-control-prepended"
                    placeholder={this.props.t("sidebar.search")}
                    type="search"
                    value={this.state.searchTerm}
                    onChange={this.onInputChange}
                  />
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroup>
              </Form>
              <SearchContainer
                items={itemsFound}
                apiAction={this.props.apiAction}
                onSelect={this.clearSearch}
              />
              {/* Navigation */}
              <Nav navbar className="sidebar-full-height">
                {this.showOrganizationDropdown() && (
                  <OrganizationDropdown
                    iconColor={
                      this.props.alerts.total > 0
                        ? "text-warning"
                        : "text-primary"
                    }
                    title={this.context.activeOrganization.name}
                    listItems={this.state.organizationsList.map((org) => ({
                      id: org.id,
                      text: org.name,
                      isAlert:
                        utils.countAlertsForCustomer(
                          this.props.alerts,
                          org.guid
                        ) > 0,
                      command: () => this.updateActiveOrganization(org),
                    }))}
                  />
                )}
                {this.createLinks(routes, canRegisterNewGateway)}
                <span className="sidebar-docs-separator">
                  {this.menuSeparator()}
                </span>
                <div className="nav-link mt-md-auto">
                  <button
                    className="sidebar-docs-toggle"
                    onClick={() => {
                      const menu = document.getElementById("sidebar-docs-menu");
                      if (menu.classList.contains("show")) {
                        menu.classList.remove("show");
                      } else {
                        menu.classList.add("show");
                      }
                    }}
                  >
                    <span>{keys.ICON_QUESTION_CIRCLE}</span>
                    <span>{this.props.t("sidebar.documents")}</span>
                  </button>
                  <div
                    id="sidebar-docs-menu"
                    className={`sidebar-docs-menu bg-white`}
                  >
                    <a
                      href={`${keys.XP_REPORTING_API_URL}/api/supported_devices`}
                      target="_blank"
                    >
                      {this.props.t("sidebar.supportedDevices")}
                    </a>
                    <a
                      href={keys.XP_QUICK_INSTALLATION_GUIDE_IPL600_URL}
                      target="_blank"
                    >
                      {this.props.t("sidebar.quickGuide")} - IPL600
                    </a>
                    <a
                      href={keys.XP_QUICK_INSTALLATION_GUIDE_XIG100_URL}
                      target="_blank"
                    >
                      {this.props.t("sidebar.quickGuide")} - XIG100
                    </a>
                  </div>
                </div>
              </Nav>

              {creativeTimDocSection}
            </Collapse>
          </div>
        </Navbar>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => {
    return alerts;
  }
);

const getGateways = createSelector(
  (state) => state.gateways,
  (gateways) => {
    let gws = [];

    Object.keys(gateways.byId).forEach((gwGuid) => {
      const gw = gateways.byId[gwGuid];

      gws.push({
        name: gw.name,
        macAddress: gw.macAddress,
        licenses: gw.licenses,
      });
    });

    return gws;
  }
);

const mapStateToProps = (state) => {
  const { searchItems } = mapSearchItemsToProps(state);

  return {
    alerts: getAlerts(state),
    gateways: getGateways(state),
    searchItems,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);

Sidebar.defaultProps = {
  onlyAdmin: false,
};
