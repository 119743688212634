import React, { useState, useEffect } from "react";
import { Table, Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import api from "../../services/backendService";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../Notify";
import "./parameters.css";
import { useAuthorizationContext } from "../../contexts/AuthorizationContext";
import Loader from "../Loader";

const DongleParameters = ({ gatewayInfo }) => {
  const { t } = useTranslation("common");
  const { hasWritePermission } = useAuthorizationContext();

  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const writePermission = hasWritePermission(gatewayInfo?.gatewayGuid);
  const hasChanges = parameters.some((par) => par.parChanged);

  const showNotification = (type, message) => {
    notifyStore.addNotification({
      ...tempOptions,
      content: NotifyContent(type, message),
    });
  };

  useEffect(() => {
    const fetchParameters = async () => {
      try {
        const data = (await api.getGatewayParameters(gatewayInfo.gatewayGuid))
          .data;
        if (data) {
          const formattedData = data.map((param) => ({
            ...param,
            originalOption: param.selectedOption,
            parChanged: false,
          }));
          setParameters(formattedData);
        } else {
          showNotification("danger", t("xigConf.errorFetching"));
        }
      } catch (err) {
        const message = err.data?.message;
        showNotification("danger", message || t("xigConf.error"));
      } finally {
        setLoading(false);
      }
    };

    fetchParameters();
  }, [gatewayInfo, t]);

  const onChangeValue = (e, index) => {
    const { value } = e.target;
    if (writePermission) {
      setParameters((prev) =>
        prev.map((par, i) =>
          i === index
            ? {
                ...par,
                selectedOption: parseInt(value, 10),
                parChanged: parseInt(value, 10) !== par.originalOption,
              }
            : par
        )
      );
    }
  };

  const writeChanges = async () => {
    try {
      const changedParameters = parameters
        .filter((par) => par.parChanged)
        .map((par) => ({
          label: par.label,
          selectedOption: par.selectedOption,
        }));

      await api.editGatewayParameters(
        gatewayInfo.gatewayGuid,
        changedParameters
      );

      setParameters((prev) =>
        prev.map((par) => ({
          ...par,
          originalOption: par.selectedOption,
          parChanged: false,
        }))
      );

      showNotification("success", t("xigConf.changesSaved"));
    } catch (err) {
      const message = err.data?.message;
      showNotification("danger", message || t("xigConf.error"));
    } finally {
      setShowModal(false);
    }
  };

  const forgetChanges = () => {
    setParameters((prev) =>
      prev.map((par) => ({
        ...par,
        selectedOption: par.originalOption,
        parChanged: false,
      }))
    );
    setShowModal(false);
  };

  const renderButtons = (modal = false) => (
    <div
      className={`${!modal ? "dongle-par-buttons-container" : "par-actions"}`}
    >
      <Button
        id="writeChanges"
        className="btn-parameters me-2"
        color="primary"
        disabled={!hasChanges || !writePermission || gatewayInfo?.status?.toUpperCase() === "OFF"}
        onClick={writeChanges}
      >
        {t("parameters.writeChanges")}
      </Button>
      <Button
        id="forgetChanges"
        className="btn-parameters"
        color="primary"
        disabled={!hasChanges || !writePermission}
        onClick={forgetChanges}
      >
        {t("parameters.forgetChanges")}
      </Button>
    </div>
  );

  const renderTable = () => (
    <Table bordered size="sm">
      <thead>
        <tr className="bg-info text-white">
          <th className="dongle-par-column-group">{t("parameters.group")}</th>
          <th className="dongle-par-column-label">{t("parameters.label")}</th>
          <th className="dongle-par-column-description">
            {t("parameters.description")}
          </th>
          <th className="dongle-par-column-value">{t("parameters.value")}</th>
        </tr>
      </thead>
      <tbody>
        {parameters.length === 0 ? (
          <tr>
            <td colSpan="4">{t("xigConf.notAvailable")}</td>
          </tr>
        ) : (
          parameters.map((par, index) => (
            <tr
              key={par.label}
              className={`par-item ${
                index % 2 === 0 ? "btn-secondary" : "btn-white"
              }`}
            >
              <td className="dongle-par-column-group">
                {t("xigConf.configuration")}
              </td>
              <td className="dongle-par-column-label">{par.label}</td>
              <td className="dongle-par-column-description">
                {t(`xigConf.${par.label}`)}
              </td>
              <td
                className={`dongle-par-column-value ${
                  par.parChanged ? "bg-success" : ""
                }`}
              >
                <select
                  className={`par-label-list bg-transparent ${
                    par.parChanged ? "text-white" : ""
                  }`}
                  value={par.selectedOption}
                  disabled={!writePermission}
                  onChange={(e) => onChangeValue(e, index)}
                >
                  {Object.entries(par.options).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );

  const renderModal = () => (
    <Modal centered isOpen={showModal} toggle={() => setShowModal(!showModal)}>
      <ModalBody>{renderButtons(true)}</ModalBody>
    </Modal>
  );

  if (loading) return <Loader />;

  return (
    <div className="par-list mt-4">
      <div className="d-none d-md-block">{renderButtons()}</div>
      <div className="d-block d-md-none">
        <Button
          className="btn-block my-3"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          {t("parameters.parameterOptions")}
        </Button>
      </div>
      {renderModal()}
      {renderTable()}
    </div>
  );
};

export default DongleParameters;
