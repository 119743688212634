import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";

import SelectSearch, { fuzzySearch } from "react-select-search";
import keys from "configs/constants";
import api from "services/backendService";
import { withTranslation } from "react-i18next";

class CustomersAdmin extends React.Component {
  state = {
    dataOptions: [],
    selected: false,
    createNew: false,
    errorState: "",
    total: 0,
  };

  async componentDidMount() {
    const pload = await api.getCustomersDetails();
    this.setState({ dataOptions: pload.data.data, total: pload.data.total });
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  async reloadCustomers(pl) {
    if (pl.status === 200 || pl.status === 201 || pl.status === 204) {
      const pload = await api.getCustomersDetails();
      if (pload.status === 500) {
        this.setState({
          dataOptions: pload.data.data,
          response: "Internal Server Error",
          errorState: "Error",
          total: pload.data.total,
        });
        return;
      }
      this.setState({
        dataOptions: pload.data.data,
        response: pl,
        errorState: "OK",
        total: pload.data.total,
      });
    } else this.setState({ response: pl.message, errorState: "Error" });
  }

  async addCustomer() {
    const pload = await api.addCustomer(this.state.name.trim());
    await this.reloadCustomers(pload);
  }

  async deleteCustomer() {
    const pload = await api.deleteCustomer(this.state.selectedGuid, true);
    await this.reloadCustomers(pload);
  }

  async updateCustomer() {
    const pload = await api.updateCustomer(
      this.state.name.trim(),
      this.state.selectedGuid,
    );
    await this.reloadCustomers(pload);
  }

  async onDataSelected(idx) {
    if (idx === 0) {
      this.setState({
        createNew: true,
        selected: true,
        response: null,
        selectedGuid: this.state.dataOptions[idx].customerGuid,
        selectedIdx: idx,
        name: "",
        errorState: "",
      });
    } else {
      const cGuid = this.state.dataOptions[idx].customerGuid;
      this.setState({
        createNew: false,
        selected: true,
        response: null,
        selectedGuid: cGuid,
        selectedIdx: idx,
        name: this.state.dataOptions[idx].name,
        errorState: "",
        permissions: [], // TODO: This is a fast temporary solution
      });
    }
  }

  renderForm() {
    if (this.state.createNew) {
      // This is to create new Customer
      return (
        <Form role="form">
          <div className="text-center">
            {this.props.t("customerAdmin.enterCustomerName") +
              ' "' +
              this.props.t("customerAdmin.createCustomerBtn") +
              '"'}
            <br />
            <br />
          </div>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
              <Input
                placeholder={this.props.t("customerAdmin.name")}
                type="text"
                onChange={this.onNameChange.bind(this)}
                value={this.state.name}
              />
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button
              className="mt-4"
              color="primary"
              type="button"
              onClick={this.addCustomer.bind(this)}
            >
              {this.props.t("customerAdmin.createCustomerBtn")}
            </Button>
          </div>
          <br />
          <br />
          {this.state.errorState === ""
            ? ""
            : "This is the response (" + this.state.errorState + ")"}
          <br />
          <br />
          <div className="border">
            {this.state.response !== null
              ? JSON.stringify(this.state.response)
              : ""}
          </div>
        </Form>
      );
    } else {
      // This is Update/Delete Customer
      return (
        <Form role="form">
          <div className="text-center">
            {this.props.t("customerAdmin.editCustomer")}
            <br />
            <br />
          </div>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
              <Input
                placeholder={this.props.t("customerAdmin.name")}
                type="text"
                onChange={this.onNameChange.bind(this)}
                value={this.state.name}
              />
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button
              className="mt-4"
              color="primary"
              type="button"
              onClick={this.updateCustomer.bind(this)}
            >
              {this.props.t("customerAdmin.updateCustomer")}
            </Button>
            <Button
              className="mt-4"
              color="primary"
              type="button"
              onClick={this.deleteCustomer.bind(this)}
            >
              {this.props.t("customerAdmin.deleteCustomer")}
            </Button>
          </div>
          <br />
          <br />
          {this.state.errorState === ""
            ? ""
            : "This is the response (" + this.state.errorState + ")"}
          <br />
          <br />
          <div className="border">
            {this.state.response !== null
              ? JSON.stringify(this.state.response)
              : ""}
          </div>
        </Form>
      );
    }
  }

  render() {
    var dataOpt = this.state.dataOptions;
    if (dataOpt.length === 0 || dataOpt[0].customerGuid !== "0") {
      dataOpt.unshift({
        customerGuid: "0",
        name: this.props.t("customerAdmin.createNewCustomer") + "...",
      });
    }

    const selectOptions = dataOpt.map((data, key) => ({
      label: data.name,
      value: key,
      name:
        data.customerGuid === "0"
          ? data.name
          : data.name + "  [" + data.customerGuid + "]",
    }));

    return (
      <>
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader>
                  <div className="w-50">
                    <SelectSearch
                      filterOptions={fuzzySearch}
                      options={selectOptions}
                      name="customer"
                      placeholder={
                        this.props.t("customerAdmin.selectCustomer") +
                        "... (" +
                        this.props.t("customerAdmin.total") +
                        ": " +
                        this.state.total +
                        " )"
                      }
                      search={true}
                      onChange={this.onDataSelected.bind(this)}
                      autoComplete="on"
                    />
                  </div>
                </CardHeader>
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {this.state.selected ? this.renderForm() : ""}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(CustomersAdmin);
