import React, { createContext, useState, useContext } from "react";
import { store as reduxStore } from "../index";

export const AuthorizationContext = createContext(null);

export const AuthorizationContextProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [activeOrganization, setActiveOrganization] = useState({});

  const getGroupByGuid = (groupGuid) => {
    const { customers, sites, gateways } = reduxStore.getState();

    if (customers.byId[groupGuid]) {
      return customers.byId[groupGuid];
    }

    if (sites.byId[groupGuid]) {
      return sites.byId[groupGuid];
    }

    if (gateways.byId[groupGuid]) {
      return gateways.byId[groupGuid];
    }

    return null;
  };

  /* context methods */

  const isSuperAdmin = () => reduxStore.getState().user.role === "Admin";

  const isMemberOfSomeOrganization = () =>
    isSuperAdmin() || groups.some((group) => group.type === "Organization");

  const canRegisterNewGateway = () => isMemberOfSomeOrganization();

  const hasPermission = (groupGuid, permission) => {
    if (isSuperAdmin()) {
      return true;
    }

    const groupData = getGroupByGuid(groupGuid);

    if (!groupData) {
      return false;
    }

    const group = groups.find((g) => g.id === groupData.groupId);
    return !group ? false : group.role.permissions.includes(permission);
  };

  const getSitesWithWritePermission = () => {
    const groupIds = groups
      .filter(
        (group) =>
          group.type === "Site" &&
          (isSuperAdmin() ||
            group.role.permissions.includes(Permissions.GroupWrite))
      )
      .map((group) => group.id);
    const { sites } = reduxStore.getState();
    return Object.values(sites.byId).filter((site) =>
      groupIds.includes(site.groupId)
    );
  };

  const hasWritePermission = (groupGuid) =>
    hasPermission(groupGuid, Permissions.GroupWrite);

  return (
    <AuthorizationContext.Provider
      value={{
        activeOrganization,
        setActiveOrganization,
        groups,
        setGroups,
        isSuperAdmin,
        isMemberOfSomeOrganization,
        canRegisterNewGateway,
        hasPermission,
        hasWritePermission,
        getSitesWithWritePermission,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export function useAuthorizationContext() {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      "useAuthorizationContext must be used within a AuthorizationContextProvider"
    );
  }
  return context;
}

export class Permissions {
  static GroupRead = "group.read";
  static GroupWrite = "group.write";
  static GroupUsersRead = "group.users.read";
  static GroupUsersWrite = "group.users.write";
  static GroupRolesWrite = "group.roles.write";
  static GroupDevicesOwnership = "group.devices.ownership";
}
