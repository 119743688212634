import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ListDropdown from "components/ListDropdown";
import Loader from "components/Loader";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setGateways } from "../../redux/actions";
import utils from "../../utils.js";
import "./style.css";
import api from "../../services/backendService";
import isBoolean from "validator/lib/isBoolean";
import Selector from "components/Selector";
import icons from "configs/constants";
import { ActiveLicenses } from "./ActiveLicenses";
import { LicenseList } from "./LicenseList";
import {
  AuthorizationContext,
  Permissions,
} from "contexts/AuthorizationContext";
import AddLicenseModal from "./AddLicenseModal";
import { TooltipButton } from "components/Tooltip";

class License extends Component {
  static contextType = AuthorizationContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activeTab: 0,
      newActivationCode: null,
      licenses: [],
      showLicenseAssignModal: false,
      selectedEntities: {
        siteGuid: null,
        gatewayGuid: null,
        licenseGuid: null,
      },
      activationMode: false, // false: activate now, true: activate once current expires
      showLeftLicense: false,
      showAddLicenseModal: false,
    };
  }

  componentDidMount = async () => {
    if( Object.keys(this.props.customers.byId).length > 0 )
    {
      await this.getLicenses();
      this.organizationGuids = await this.getSubscriptionsGuids();
    }
  };

  componentDidUpdate= async (prevProps)=>  {
    if (prevProps.customers.byId !== this.props.customers.byId) {
      await this.getLicenses();
      this.organizationGuids = await this.getSubscriptionsGuids();
    }
  }
  
  getSubscriptionsGuids = async (
    permission = Permissions.GroupWrite,
  ) => {
    if (this.context.isSuperAdmin()) {
      // Admin can see all subscriptions
      if (permission !== Permissions.GroupWrite) {
        // Request for viewed subscription is ignored to avoid duplication of licenses
        return [];
      }
    }

    let filteredCustomers = this.props.customers.byId;
    if (!this.context.isSuperAdmin() && this.context.activeOrganization) {
      // Filter customers by the active organization GUID
      filteredCustomers = Object.values(filteredCustomers).filter(
          (c) => c.customerGuid === this.context.activeOrganization.guid
      );
    } else {
      // Convert the dictionary to an array of customer objects
      filteredCustomers = Object.values(filteredCustomers);
    }
    
    // Filter and map customers based on the required permission
    const customers = filteredCustomers.filter((x) =>
        this.context.hasPermission(x.customerGuid, permission)
    );

    return customers.map((x) => x.customerGuid);
  };

  getLicenses = async () => {
    this.setState({ isLoading: true });

    try {
      const ownedLicensesResponse =
        await api.getLicensesByListOfSubscriptionGuids(
          await this.getSubscriptionsGuids()
        );
      const viewedLicensesResponse =
        await api.getLicensesByListOfSubscriptionGuids(
          await this.getSubscriptionsGuids(Permissions.GroupRead)
        );

      const ownedLicenses = ownedLicensesResponse.data.data.map((l) => ({
        ...l,
        owned: true,
      }));
      const viewedLicenses = viewedLicensesResponse.data.data.map((l) => ({
        ...l,
        owned: false,
      }));

      let licenses = ownedLicenses;

      viewedLicenses.forEach((l) => {
        if (
          licenses.filter((x) => x.licenseGuid === l.licenseGuid).length === 0
        ) {
          licenses.push(l);
        }
      });

      licenses = utils.setLicensesInfo(licenses);

      this.setState({ isLoading: false, licenses });
    } catch (error) {
      utils.debug("Error fetching licenses:", error);
      this.setState({ isLoading: false });
    }
  };

  toggleLicenseAssignModal = (status = undefined) => {
    let { showLicenseAssignModal } = this.state;

    showLicenseAssignModal = !showLicenseAssignModal;

    this.setState({
      activationMode: false,
      showLicenseAssignModal:
        status && isBoolean(status.toString())
          ? status
          : showLicenseAssignModal,
    });
  };

  selectEntities = (
    siteGuid = null,
    gatewayGuid = null,
    licenseGuid = null,
    ignoreNull = true
  ) => {
    let { selectedEntities } = this.state;

    selectedEntities.siteGuid = !siteGuid
      ? ignoreNull
        ? selectedEntities.siteGuid
        : null
      : siteGuid;
    selectedEntities.gatewayGuid = !gatewayGuid
      ? ignoreNull
        ? selectedEntities.gatewayGuid
        : null
      : gatewayGuid;
    selectedEntities.licenseGuid = !licenseGuid
      ? ignoreNull
        ? selectedEntities.licenseGuid
        : null
      : licenseGuid;

    this.setState({ selectedEntities });
  };

  printException = (e) => {
    if (e.status) {
      switch (e.status) {
        case 403:
          break;
        default:
          utils.debug(e.data, utils.MSG_TYPE_ERR);
          break;
      }
    } else {
      utils.debug(e, utils.MSG_TYPE_ERR);
    }
  };

  assignLicenseToGateway = async () => {
    this.setState({ isLoading: true });
    this.toggleLicenseAssignModal(false);
    
    const customerGuid = this.context.isSuperAdmin()
        ? null
        : this.context.activeOrganization.guid;
    
    try {
      // Activate license or set activation date
      let response = await api.editLicense(
        this.state.selectedEntities.licenseGuid,
        this.state.selectedEntities.gatewayGuid,
        null,
        this.state.activationMode ? 0 : 1
      );

      if (!response.status || (response.status && response.status !== 200)) {
        throw new Error("Invalid response\nThe edit of the license failed");
      }
    } catch (e) {
      this.printException(e);
    }
    await this.getLicenses();

    const gateways = await api.getGatewayInitialState(customerGuid,null, null, false);

    this.setState(
      {
        isLoading: false,
        selectedEntities: {
          siteGuid: null,
          gatewayGuid: null,
          licenseGuid: null,
        },
      },
      () => this.props.setGateways(gateways)
    );
  };

  getAvailableLicenses = (licenses = []) => {
    let availableLicenses = {};

    licenses
      .filter((l) => !l.isActive && !l.isExpired && !l.gateway && !l.activeFrom)
      .forEach((license) => {
        const { name, data } = JSON.parse(license.content);
        const maxDevices = data.maxDevices.frontend;
        const { licenseTime } = license;
        if (!availableLicenses[name + " - " + licenseTime]) {
          availableLicenses = {
            ...availableLicenses,
            [name + " - " + licenseTime]: {
              licenseGuid: license.licenseGuid,
              remaining: 1,
              maxDevices: maxDevices,
            },
          };
        } else {
          availableLicenses[name + " - " + licenseTime].remaining++;
        }
      });

    let r = document.querySelector(":root");
    r.style.setProperty(
      "--license-left-height",
      52 * Object.keys(availableLicenses).length + 10 + "px"
    );

    return availableLicenses;
  };

  renderLicenseAssignModal = () => {
    let licenseList = [];

    let availableLicenses = this.getAvailableLicenses(this.state.licenses);

    Object.keys(availableLicenses).forEach((license) => {
      const licenseMaxDevices = availableLicenses[license].maxDevices;
      licenseList.push({
        text: (
          <div className="license-available-container">
            <div>
              <div className="license-available-title">{license}</div>
              <div className="license-available-controllers">
                {licenseMaxDevices +
                  " " +
                  this.props.t("license.controllers").toLowerCase()}
              </div>
              <div className="license-available-remaining-time">
                {availableLicenses[license].remaining +
                  " " +
                  this.props.t("license.availableLicense").toLowerCase()}
              </div>
            </div>
          </div>
        ),
        command: () => {
          this.selectEntities(
            null,
            null,
            availableLicenses[license].licenseGuid
          );
        },
      });
    });

    const siteName = this.state.selectedEntities.siteGuid
      ? this.props.sites[this.state.selectedEntities.siteGuid].name
      : "";
    const gatewayName = this.state.selectedEntities.gatewayGuid
      ? this.props.sites[this.state.selectedEntities.siteGuid].gateways[
          this.state.selectedEntities.gatewayGuid
        ].name
      : "";

    let licenseName = "";

    if (this.state.selectedEntities.licenseGuid) {
      const license = this.state.licenses.filter(
        (license) =>
          license.licenseGuid === this.state.selectedEntities.licenseGuid
      )[0];

      licenseName =
        JSON.parse(license.content).name + " - " + license.licenseTime;
    }

    let selectorDisabled = !(
      this.state.selectedEntities.siteGuid !== null &&
      this.state.selectedEntities.gatewayGuid !== null &&
      this.state.selectedEntities.licenseGuid !== null &&
      this.props.sites[this.state.selectedEntities.siteGuid].gateways[
        this.state.selectedEntities.gatewayGuid
      ].licenses.filter((license) => license.isActive === true).length > 0
    );

    if (
      this.state.selectedEntities.gatewayGuid &&
      this.props.gateways.byId[
        this.state.selectedEntities.gatewayGuid
      ].licenses.filter((l) => !l.isExpired).length > 1
    ) {
      selectorDisabled = true;
    }

    let selectorDefault = false;

    let buttonDisabled = !(
      this.state.selectedEntities.siteGuid !== null &&
      this.state.selectedEntities.gatewayGuid !== null &&
      this.state.selectedEntities.licenseGuid !== null
    );

    let warning = false;

    if (this.state.selectedEntities.gatewayGuid) {
      warning =
        this.props.gateways.byId[
          this.state.selectedEntities.gatewayGuid
        ].licenses.filter((l) => l.isActive).length === 0;
    }

    return (
      <Modal
        isOpen={this.state.showLicenseAssignModal}
        toggle={this.toggleLicenseAssignModal}
        centered
        className="license-assign-modal"
      >
        <ModalBody>
          <div className="h2">{this.props.t("license.assignLicense")}</div>
          <table>
            <tbody>
              <tr>
                <th className="pr-2">{this.props.t("license.site") + ":"}</th>
                <td>{siteName}</td>
              </tr>
              <tr>
                <th className="pr-2">
                  {this.props.t("license.gateway") + ":"}
                </th>
                <td>{gatewayName}</td>
              </tr>
            </tbody>
          </table>
          <div className="mt-3">
            <span>{this.props.t("license.selectLicense") + ":"}</span>
            <ListDropdown
              toggleClass="ml-4"
              toggleMenu="menu-licenses"
              title={
                licenseName || this.props.t("license.selectLicense") + "..."
              }
              listItems={licenseList}
            />
          </div>
          <div
            className={`${warning ? "border border-info p-2 mt-4" : "d-none"}`}
          >
            {this.props.t("license.noActiveLicenseWarning")}
          </div>
          <div className="mt-4 d-flex justify-content-between align-items-end">
            <span>
              <div>{this.props.t("license.activateLicense") + ":"}</div>
              <Selector
                id="activationMode"
                className="ml--1"
                status={this.state.activationMode}
                items={[
                  this.props.t("license.now"),
                  this.props.t("license.onceCurrentExpires"),
                ]}
                toggle={() =>
                  this.setState({
                    activationMode: !this.state.activationMode,
                  })
                }
                disabled={selectorDisabled}
                default={selectorDefault}
              />
            </span>
            <Button
              color="primary"
              className="h-50"
              disabled={buttonDisabled}
              onClick={this.assignLicenseToGateway}
            >
              {!this.state.activationMode
                ? this.props.t("license.activateLicense")
                : this.props.t("license.assignLicense")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    let licenseList = [];

    this.state.licenses.forEach((license) => {
      const { name } = JSON.parse(license.content);
      licenseList.push({
        text: name,
      });
    });

    let availableLicenses = this.getAvailableLicenses(this.state.licenses);

    return (
      <>
        {this.state.isLoading ? <Loader /> : null}
        {this.renderLicenseAssignModal()}
        <Card className="m-4 mt--9">
          <CardBody>
            <Nav pills fill className="nav-license">
              <NavItem>
                <NavLink
                  onClick={() => this.setState({ activeTab: 0 })}
                  className={`nav-license-tab ${
                    this.state.activeTab === 0 ? "nav-license-tab-active" : ""
                  }`}
                >
                  {this.props.t("license.licenseManagement")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => this.setState({ activeTab: 1 })}
                  className={`nav-license-tab ${
                    this.state.activeTab === 1 ? "nav-license-tab-active" : ""
                  }`}
                >
                  {this.props.t("license.licenseState")}
                </NavLink>
              </NavItem>
            </Nav>
            <div className="my-4 license-head">
              <span
                className="license-head-left"
                // show={this.state.showLeftLicense.toString()}
              >
                <span
                  className="license-head-left-title"
                  onClick={() => {
                    this.setState({
                      showLeftLicense: !this.state.showLeftLicense,
                    });
                  }}
                >
                  <span className="text-light">{icons.ICON_INFO}</span>
                  <span className="license-head-left-text ml-1">
                    <span>{this.props.t("license.availableLicense")}</span>
                    <span>:</span>
                  </span>
                </span>
                <span
                  className="license-left-container"
                  // show={this.state.showLeftLicense.toString()}
                >
                  {Object.keys(availableLicenses).map((l) => {
                    const { remaining } = availableLicenses[l];
                    const name = l.split(" - ")[0];
                    const nDevices = l.split(" - ")[1];
                    return (
                      <span
                        key={availableLicenses[l].licenseGuid}
                        className="m-1 border p-1 rounded"
                      >
                        <span className="font-weight-light">
                          {remaining + " x "}
                        </span>
                        <span>{name}</span>
                        <span className="font-weight-light">
                          {" - " + nDevices}
                        </span>
                      </span>
                    );
                  })}
                </span>
              </span>
              <div className="license-modal-container">
                <Button
                  id="btnAddLicense"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showAddLicenseModal: !this.state.showAddLicenseModal,
                    })
                  }
                  className="btn-add-license float-right"
                  disabled={(this.organizationGuids ?? []).length === 0}
                >
                  <span>{this.props.t("license.addLicense")}</span>
                  <TooltipButton
                    target="btnAddLicense"
                    text={
                      (this.organizationGuids ?? []).length === 0
                        ? this.props.t("license.noOwnedOrganizationsWarning")
                        : ""
                    }
                  />
                </Button>
                <AddLicenseModal
                  isOpen={this.state.showAddLicenseModal}
                  closeModal={() =>
                    this.setState({ showAddLicenseModal: false })
                  }
                  t={this.props.t}
                  organizationGuids={this.organizationGuids}
                  setLoader={(state) => this.setState({ isLoading: state })}
                  refresh={this.getLicenses}
                />
              </div>
            </div>
            <TabContent
              activeTab={this.state.activeTab}
              className="license-card-content"
            >
              <TabPane tabId={0}>
                <ActiveLicenses
                  sites={this.props.sites}
                  t={this.props.t}
                  selectEntities={this.selectEntities}
                  assignLicense={this.toggleLicenseAssignModal}
                  showAsList={Object.keys(this.props.sites).length > 3}
                  language={this.props.language}
                />
              </TabPane>
              <TabPane tabId={1}>
                {this.state.isLoading ? null : (
                  <LicenseList
                    licenses={this.state.licenses}
                    t={this.props.t}
                    language={this.props.language}
                    sites={this.props.sites}
                    gateways={this.props.gateways}
                  />
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </>
    );
  }
}

const setSites = (state) => {
  let sites = {};

  for (let gatewayGuid in state.gateways.byId) {
    let gateway = state.gateways.byId[gatewayGuid];
    const { site } = gateway;

    let gateways = sites[site.siteGuid] ? sites[site.siteGuid].gateways : {};
    sites = {
      ...sites,
      [site.siteGuid]: {
        name: site.name,
        gateways: { ...gateways, [gatewayGuid]: gateway },
      },
    };
  }
  return sites;
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  gateways: state.gateways,
  sites: setSites(state),
  language: state.user.language ? state.user.language.split("_")[1] : "en-GB",
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setGateways,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(License);
