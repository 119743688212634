import React from "react";
import { Button, Form, InputGroup, InputGroupText, Input } from "reactstrap";
import validator from "validator";

import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";

class DigitalThreshold extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      receiversError: false,
      [this.props.th.thresholdGuid + "_radio"]: this.props.mode || "rising",
      receivers: this.props.receivers || "",
    };

    this.originalState = JSON.parse(JSON.stringify(this.state));
  }

  onInputChange(evt) {
    let error = false;
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    if (name === "receivers") {
      for (const r of utils.getAlertReceiversArray(value)) {
        if (validator.isEmail(r) === false) {
          error = true;
          break;
        }
      }
      this.setState({ [name]: value, receiversError: error });
    } else this.setState({ [name]: value });
  }

  async onSaveChangesClicked(_evt) {
    let same = utils.checkDifferencesOnThreshold(
      this.state,
      this.originalState,
      false
    );

    const guid = this.props.th.thresholdGuid;
    if (this.props.onApplyClick) {
      if (!same.attributes) {
        let response = await api.editExistingThreshold({
          thresholdGuid: guid,
          rangePercentage: 1,
          minValue: this.state[guid + "_radio"] === "falling" ? 0.5 : -1,
          maxValue: this.state[guid + "_radio"] === "rising" ? 0.5 : 2,
          fieldName: this.props.th.fieldName,
          label: this.props.th.label,
        });
        if (response.status !== 200) {
          // Reset to original value
          this.setState({
            [guid + "_radio"]: this.originalState[guid + "_radio"],
          });
        }
      }

      const receivers = utils.getAlertReceiversArray(this.state.receivers);

      if (!same.receivers) {
        if (receivers.length) {
          let thnotify = [];
          for (const r of receivers) {
            thnotify.push({
              email: r,
              thresholdGuid: guid,
              enableNotification: "ON",
            });
          }
          await api.deleteAllThresholdNotifyForThreshold(guid);
          let response = await api.createOrUpdateThresholdNotify(thnotify);
          if (response !== 200) {
            // Bad response (perhaps due to license limitation). Reload email list anyway
            try {
              let { data } = (await api.getAllThresholdNotify(guid)).data;

              let emailList = [];

              data.forEach((item) => {
                emailList.push(item.email);
              });

              this.setState({ receivers: emailList.join("; ") });
            } catch (ex) {}
          }
        } else {
          await api.deleteAllThresholdNotifyForThreshold(guid);
        }
      }
      this.props.onApplyClick();
    }

    this.originalState = JSON.parse(JSON.stringify(this.state));
  }

  render() {
    const guid = this.props.th.thresholdGuid;

    let same = utils.checkDifferencesOnThreshold(
      this.state,
      this.originalState,
      false
    );

    return (
        <Form>
          {
              this.props.th.fieldName.trim().toLowerCase() !== "nointernet" &&
              <>
                <div className="custom-control custom-control-alternative custom-radio mb-3">
                  <input
                      className="custom-control-input"
                      id={guid + "_radioRising"}
                      name={guid + "_radio"}
                      type="radio"
                      value="rising"
                      checked={this.state[guid + "_radio"] === "rising"}
                      onChange={this.onInputChange.bind(this)}
                  />
                  <label
                      className="custom-control-label"
                      htmlFor={guid + "_radioRising"}
                  >
                    {this.props.t("deviceThresholds.activeOnRisingEdge")}
                    {this.props.vtype === "alarm" ? " ( predefined ) " : null}
                  </label>
                </div>
                <div className="custom-control custom-control-alternative custom-radio mb-3">
                  <input
                      className="custom-control-input"
                      id={guid + "_radioFalling"}
                      name={guid + "_radio"}
                      type="radio"
                      value="falling"
                      checked={this.state[guid + "_radio"] === "falling"}
                      onChange={this.onInputChange.bind(this)}
                  />
                  <label
                      className="custom-control-label"
                      htmlFor={guid + "_radioFalling"}
                  >
                    {this.props.t("deviceThresholds.activeOnFallingEdge")}
                  </label>
                </div>
              </>
          }
        <InputGroup className="mb-3">
          <InputGroupText id="group-name">{keys.ICON_RECEIVERS}</InputGroupText>
          <Input
            placeholder={this.props.t(
              "deviceThresholds.multipleReceiversPlaceHolder"
            )}
            name="receivers"
            type="text"
            onChange={this.onInputChange.bind(this)}
            value={this.state.receivers}
            className={
              this.state.receiversError ? " bg-warning text-white " : null
            }
          />
        </InputGroup>
        <div className="text-center">
          <Button
            className="ml-md-5 btn-icon"
            color="primary"
            type="button"
            onClick={this.onSaveChangesClicked.bind(this)}
            disabled={
              //this.state.receivers === "" ||
              this.state.receiversError || (same.attributes && same.receivers)
            }
          >
            <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
            <span className="btn-inner--text">
              {this.props.t("deviceThresholds.apply")}
            </span>
          </Button>
        </div>
      </Form>
    );
  }
}

export default DigitalThreshold;
