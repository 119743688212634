import React, { Component } from "react";
import { Container, Card, Button, Modal, ModalBody, Table } from "reactstrap";
import ListDropdown from "../components/ListDropdown";
import { Redirect } from "react-router-dom";
import Loader from "../components/Loader";
import { TooltipButton } from "../components/Tooltip";
import utils from "../utils";
import "./alarmLog.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setControllers, resetControllers } from "redux/actions/index";

import { createSelector } from "reselect";
import api from "../services/backendService";
import keys from "../configs/constants";

import { withTranslation } from "react-i18next";
import { AuthorizationContext } from "../contexts/AuthorizationContext";

const ALERT_ERROR = 0x7fff;
const ALERT_OK = 0x0000;

const HEADER_SITE = "site";
const HEADER_DEVICE = "device";
const HEADER_MEASURE = "alarmMeasure";
const HEADER_VALUE = "value";
const HEADER_CONDITION = "alarmCondition";
const HEADER_START = "start";
const HEADER_END = "end";
const HEADER_DURATION = "duration";

const HEADER_TABLE = [
  HEADER_SITE,
  HEADER_DEVICE,
  HEADER_MEASURE,
  HEADER_VALUE,
  HEADER_CONDITION,
  HEADER_START,
  HEADER_END,
  HEADER_DURATION,
];

class AlarmLog extends Component {
  static contextType = AuthorizationContext;

  constructor(props) {
    super(props);

    this.props.resetControllers();

    let dateTo = new Date();

    dateTo.setHours(0);
    dateTo.setMinutes(0);
    dateTo.setSeconds(0);

    let dateFrom = utils.setOffsetDate(dateTo, 60 * 24 * 3);

    let headerIcons = [];
    for (let i = 0; i < HEADER_TABLE.length; i++) {
      headerIcons.push(0);
    }

    this.state = {
      logType: false, // false = Active alarms; true = Advanced Filter
      showFilters: false,
      dateFrom: dateFrom,
      dateTo: dateTo,
      selectedSites: this.getSitesList(),
      selectedGateways: [],
      selectedControllers: [],
      log: [],
      isLoading: true,
      sortIcon: headerIcons,
      redirect: false,
      intervalSelection: 0,
    };

    this.siteInfo = {
      siteGuid: null,
      gatewayGuid: null,
      deviceGuid: null,
      name: null,
      address: null,
    };

    this.allDevicesList = {};
    this.allThresholdsList = {};
  }

  componentDidMount = async () => {
    await this.getAllThresholds();

    let selectedGateways = this.getGatewaysList();
    let selectedControllers = this.getControllersList(selectedGateways);

    for (let device of selectedControllers) {
      if (device.deviceGuid !== undefined) {
        let deviceInfo = {
          model: device.text,
          deviceGuid: device.deviceGuid,
          siteGuid: device.siteGuid,
          friendlyName: device.friendlyName,
        };
        this.allDevicesList = {
          ...this.allDevicesList,
          [device.deviceGuid]: deviceInfo,
        };
      }
    }

    await this.getActiveAlarms();

    this.setState({
      selectedGateways: selectedGateways,
      selectedControllers: selectedControllers,
    });
  };

  componentDidUpdate(prevProps) {
    Object.entries(this.props).forEach(([key, val]) => {
      if (key === "alerts" && prevProps[key] !== val) this.getActiveAlarms();
    });
  }

  getActiveAlarms = async (force = false, toState = true) => {
    const alertsNumber = !this.context.isSuperAdmin()
      ? utils.countAlertsForCustomer(
          this.props.alerts,
          this.context.activeOrganization.guid
        )
      : this.props.alerts.total;
    let activeAlarms = [];
    let total = alertsNumber;

    const activeOrganizationGuid = this.context.isSuperAdmin()
      ? null
      : this.context.activeOrganization.guid;

    if (!this.state.logType || force) {
      while (activeAlarms.length < total) {
        activeAlarms = [];
        let alerts = (
          await api.getAlertStatus(null, null, null, activeOrganizationGuid, 1)
        ).data.data;

        for (let alert of alerts) {
          let thGuid = alert.threshold.thresholdGuid;

          let alarm = null;

          // Get the latest log of the threshold
          alarm = await api.getLatestAlertLogByThresholdGuidAsync(thGuid, 1);
          alarm.endAlarmDate = "";

          if (this.allDevicesList[alert.threshold.deviceGuid] !== undefined) {
            alarm.model = this.allDevicesList[alert.threshold.deviceGuid].model;
            alarm.friendlyName =
              this.allDevicesList[alert.threshold.deviceGuid].friendlyName;
            alarm.siteGuid =
              this.allDevicesList[alert.threshold.deviceGuid].siteGuid;
            activeAlarms.push(alarm);
          } else {
            total--;
          }
        }
      }
      this.setState({
        isLoading: false,
      });

      if (toState) {
        this.setState({
          log: await this.setAlertsForTable(activeAlarms),
        });
      } else {
        return activeAlarms;
      }
    }
  };

  getAllThresholds = async () => {
    let thresholds = (
      await api.getAllThresholdDetails(
        null,
        null,
        !this.context.isSuperAdmin()
          ? this.context.activeOrganization.guid
          : null
      )
    ).data.data;

    for (let threshold of thresholds) {
      this.allThresholdsList = {
        ...this.allThresholdsList,
        [threshold.thresholdGuid]: threshold,
      };
    }
  };

  toggleFilterModal = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  getSitesList = () => {
    let selectedSites = [];

    this.props.sites.allIds.forEach((siteGuid, i) => {
      const site = this.props.sites.byId[siteGuid];

      selectedSites.push({
        id: i,
        text: site.name,
        siteGuid: siteGuid,
        checkbox: true,
        checkboxStatus: false,
        command: () => {
          selectedSites[i].checkboxStatus = !selectedSites[i].checkboxStatus;
          let newGatewayList = this.getGatewaysList();
          let newControllersList = this.getControllersList(newGatewayList);
          this.setState({
            selectedSites,
            selectedGateways: newGatewayList,
            selectedControllers: newControllersList,
          });
        },
      });
    });

    return selectedSites;
  };

  getGatewaysList = () => {
    let selectedGateways = [];
    let allSitesSelected = true;

    let sitesList = {};

    for (let site of this.state.selectedSites) {
      if (site.checkboxStatus || site.hidden) allSitesSelected = false;
      sitesList = {
        ...sitesList,
        [site.siteGuid]: site,
      };
    }

    this.props.gateways.allIds.forEach((gatewayGuid, i) => {
      const gateway = this.props.gateways.byId[gatewayGuid];
      let hidden;
      if (allSitesSelected) hidden = false;
      else hidden = !sitesList[gateway.site.siteGuid].checkboxStatus;

      selectedGateways.push({
        id: i,
        text: gateway.name,
        devices: gateway.devices,
        gatewayGuid: gatewayGuid,
        siteGuid: gateway.site.siteGuid,
        checkbox: true,
        checkboxStatus: false,
        hidden: hidden,
        command: () => {
          selectedGateways[i].checkboxStatus =
            !selectedGateways[i].checkboxStatus;
          this.setState({
            selectedGateways,
            selectedControllers: this.getControllersList(selectedGateways),
          });
        },
      });
    });

    return selectedGateways;
  };

  getControllersList = (allGateways) => {
    let selectedControllers = [];

    let devices = [];
    let allGatewaysSelected = true;

    let gateways = [];

    for (let gateway of allGateways) {
      if (!gateway.hidden) {
        gateways.push(gateway);
        if (gateway.checkboxStatus) allGatewaysSelected = false;
      }
    }

    if (!allGatewaysSelected) {
      gateways = gateways.filter((gateway) => gateway.checkboxStatus);
    }

    let i = 0;

    for (let gateway of gateways) {
      let hidden = allGatewaysSelected ? false : !gateway.checkboxStatus;

      devices.push({
        id: i,
        text: gateway.text,
        gatewayGuid: gateway.gatewayGuid,
        header: true,
        disabled: true,
        deviceNumber: gateway.devices.length,
        hidden: hidden,
      });
      i++;
      for (let device of gateway.devices) {
        let newDevice = {
          id: i,
          deviceGuid: device.deviceGuid,
          gateway: { gatewayGuid: gateway.gatewayGuid, name: gateway.name },
          siteGuid: gateway.siteGuid,
          hidden: hidden,
          checkbox: true,
          checkboxStatus: false,
          friendlyName: device.friendlyName,
        };

        if (selectedControllers[i] === undefined)
          newDevice.text =
            device.friendlyName === undefined ||
            device.friendlyName === null ||
            device.friendlyName === ""
              ? utils.getModelFromMFVE(device.model)
              : device.friendlyName;
        else newDevice.text = selectedControllers[i].text;

        devices.push(newDevice);
        i++;
      }
    }

    devices.forEach((device, i) => {
      // Controller and Gateways
      device.command = () => {
        let { selectedControllers } = this.state;
        selectedControllers[i].checkboxStatus =
          !selectedControllers[i].checkboxStatus;
        this.setState({
          selectedControllers,
        });
      };

      selectedControllers.push(device);
    });

    selectedControllers = selectedControllers.filter(
      (device) => !device.hidden
    );

    return selectedControllers;
  };

  /**
   * getAlarmLog: get the log of alerts using the list of selected gateways and controllers
   */
  getAlertsLog = async () => {
    this.setState({ isLoading: true, log: [] });
    let result = [];

    let controllersList = {};
    let filteredControllersList = {};

    for (let device of this.state.selectedControllers) {
      if (device.deviceGuid !== undefined) {
        if (device.checkboxStatus) {
          filteredControllersList = {
            ...filteredControllersList,
            [device.deviceGuid]: device,
          };
        }
        controllersList = {
          ...controllersList,
          [device.deviceGuid]: device,
        };
      }
    }

    // Use filteredControllersList if there is at least one selected controller
    if (Object.keys(filteredControllersList).length)
      controllersList = filteredControllersList;
    // Otherwise use all controllers list

    const controllersBatches = [];
    for (let i = 0; i < Object.keys(controllersList).length; i += 30) {
      controllersBatches.push(Object.keys(controllersList).slice(i, i + 30));
    }
    const alertPromises = controllersBatches.map(async (batch) => {
      return await api.getAlertsLogByDeviceGuidsAndTimeSpan(
        batch,
        this.state.dateFrom,
        this.state.dateTo
      );
    });
    const results = await Promise.all(alertPromises);
    const combinedResults = results.flatMap((result) => result.data.data);
    result = await this.setAlertsList(combinedResults);

    result = result.filter((alert) => {
      return controllersList[alert.deviceGuid] !== undefined;
    });

    this.setState({
      log: await this.setAlertsForTable(result),
      isLoading: false,
    });
  };

  setAlertsForTable = async (result) => {
    for (let item of result) {
      try {
        if (this.allThresholdsList[item.thresholdGuid] === undefined) {
          let response = await api.getThresholdDetailsByGuid(
            item.thresholdGuid
          );
          this.allThresholdsList = {
            ...this.allThresholdsList,
            [item.thresholdGuid]: response.data,
          };
        }
        if (item.alarmDate !== undefined)
          item.start = new Date(item.alarmDate + "Z").toLocaleString();

        if (item.endAlarmDate !== undefined) {
          if (item.endAlarmDate !== "") {
            item.end = new Date(item.endAlarmDate + "Z").toLocaleString();
            item.duration = this.setDatesDifference(
              new Date(item.alarmDate + "Z"),
              new Date(item.endAlarmDate + "Z")
            );
          } else {
            item.end = this.props.t("alarmLog.alarmOn");
            item.duration = this.setDatesDifference(
              new Date(item.alarmDate + "Z"),
              new Date()
            );
          }
        }

        item.label = item.customLabel ?? utils.getLangText(item.fieldName);
        item.error = ALERT_OK;
        item.gateway = {
          name: this.props.gateways.byId[item.gatewayGuid].name,
          gatewayGuid: item.gatewayGuid,
        };
        //} else item.error = ALERT_ERROR;

        delete item.gatewayGuid;
      } catch (e) {
        // Only Admins can see archived thresholds
        item.error = ALERT_ERROR;
      }
    }

    return result.filter((item) => {
      return item.error !== ALERT_ERROR;
    });
  };

  setAlertsList = async (response) => {
    let alertList = [];
    let reverseAlertList = [];

    let activeAlarms = await this.getActiveAlarms(true, false);

    for (let i = response.length - 1; i >= 0; i--) {
      let item = JSON.parse(JSON.stringify(response[i]));

      if (
        (new Date(item.alarmDate + "Z") >= this.state.dateFrom &&
          this.state.dateTo >= new Date(item.alarmDate + "Z")) ||
        !this.state.logType
      ) {
        if (item.alertEventType === 1) {
          item.endAlarmDate = "";
          try {
            const friendlyName =
              this.allDevicesList[item.deviceGuid].friendlyName;
            item.model = this.allDevicesList[item.deviceGuid].model;
            item.friendlyName =
              friendlyName === undefined ||
              friendlyName === null ||
              friendlyName === ""
                ? null
                : friendlyName;
            item.siteGuid = this.allDevicesList[item.deviceGuid].siteGuid;
          } catch (e) {
            item.error = ALERT_ERROR;
          }

          let found = false;

          for (let j = i; j >= 0; j--) {
            if (
              response[j].thresholdGuid === item.thresholdGuid &&
              response[j].alertEventType === 2 &&
              new Date(item.alarmDate) < new Date(response[j].alarmDate)
            ) {
              item.endAlarmDate = response[j].alarmDate;
              found = true;
              break;
            }
          }
          if (!found) {
            // Check for inconsistencies
            let ok = false;
            for (let activeAlarm of activeAlarms) {
              if (activeAlarm.thresholdGuid === item.thresholdGuid) {
                ok = true;
              }
            }
            if (ok) {
              reverseAlertList.push(item);
            }
          } else {
            reverseAlertList.push(item);
          }
        }
      }
    }

    for (let i = reverseAlertList.length - 1; i >= 0; i--) {
      alertList.push(reverseAlertList[i]);
    }

    alertList = alertList.filter((item) => item.error !== ALERT_ERROR);

    return alertList;
  };

  setDatesDifference = (startDate, endDate) => {
    let duration = endDate - startDate;
    let output = [];
    let days = Math.floor(duration / (1000 * 60 * 60 * 24));
    if (days > 0) {
      output.push(days + " " + this.props.t("alarmLog.days") + " ");
      duration -= days * 1000 * 60 * 60 * 24;
    }
    let hours = Math.floor(duration / (1000 * 60 * 60));
    if (hours > 0 || days > 0) {
      output.push(hours + " " + this.props.t("alarmLog.hours") + " ");
      duration -= hours * 1000 * 60 * 60;
    }
    let minutes = Math.floor(duration / (1000 * 60));
    if (minutes > 0 || hours > 0 || days > 0) {
      output.push(minutes + " " + this.props.t("alarmLog.minutes") + " ");
      duration -= minutes * 1000 * 60;
    }
    let seconds = Math.floor(duration / 1000);
    if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) {
      output.push(seconds + " " + this.props.t("alarmLog.seconds"));
      duration -= seconds * 1000;
    }

    let result = output.join("");

    return result;
  };

  sortTable = (header, n) => {
    let table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchCount = 0,
      { sortIcon } = this.state;

    table = document.getElementById("log-table");
    switching = true;
    dir = "asc";

    for (let i = 0; i < sortIcon.length; i++) {
      sortIcon[i] = 0;
    }

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;

        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        let a, b;

        switch (header) {
          case HEADER_SITE:
          case HEADER_DEVICE:
          case HEADER_MEASURE:
          case HEADER_VALUE:
          case HEADER_CONDITION:
          case HEADER_START:
          case HEADER_END:
            a = x.innerHTML.toLowerCase();
            b = y.innerHTML.toLowerCase();
            break;
          case HEADER_DURATION:
            const getTimeFromDuration = (timeStr) => {
              let timeList = timeStr
                .split(" ")
                .filter(
                  (time) =>
                    time !== "days" &&
                    time !== "hours" &&
                    time !== "minutes" &&
                    time !== "seconds"
                )
                .map((time) => parseInt(time));

              let returnVal;

              for (let i = timeList.length - 1; i >= 0; i--) {
                switch (i) {
                  case timeList.length - 1:
                    returnVal = timeList[i];
                    break;
                  case timeList.length - 2:
                    returnVal += timeList[i] * 60;
                    break;
                  case timeList.length - 3:
                    returnVal += timeList[i] * 60 * 60;
                    break;
                  case timeList.length - 4:
                    returnVal += timeList[i] * 60 * 60 * 24;
                    break;
                  default:
                    break;
                }
              }

              return returnVal;
            };

            a = getTimeFromDuration(x.innerHTML);
            b = getTimeFromDuration(y.innerHTML);

            break;
          default:
            break;
        }

        if (dir === "asc") {
          if (a > b) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (a < b) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchCount++;
      } else {
        if (switchCount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }

    if (dir === "asc") sortIcon[n] = 1;
    else if (dir === "desc") sortIcon[n] = 2;

    this.setState({ sortIcon });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/std/sv",
            site: this.siteInfo,
          }}
        />
      );
    }

    let nSelectedSites = 0;
    let nSelectedGateways = 0;
    let nSelectedControllers = 0;

    for (let site of this.state.selectedSites) {
      if (site.checkboxStatus) nSelectedSites++;
    }

    for (let gateway of this.state.selectedGateways) {
      if (gateway.checkboxStatus) nSelectedGateways++;
    }

    for (let controllers of this.state.selectedControllers) {
      if (controllers.gatewayGuid !== undefined) {
        if (controllers.checkboxStatus)
          nSelectedControllers += controllers.deviceNumber;
      } else if (controllers.deviceGuid !== undefined) {
        if (controllers.checkboxStatus) nSelectedControllers++;
      }
    }

    const Info = (props) => {
      let sgc = [];

      switch (props.type) {
        case "sites":
          sgc = this.state.selectedSites;
          break;
        case "gateways":
          sgc = this.state.selectedGateways;
          break;
        case "controllers":
          sgc = this.state.selectedControllers.filter(
            (sgc) => sgc.gatewayGuid === undefined
          );
          break;
        default:
          break;
      }

      sgc = sgc.filter((site) => {
        return !site.hidden;
      });

      if (props.number > 0) {
        sgc = sgc.filter((site) => {
          return site.checkboxStatus;
        });
      }

      return (
        <span>
          <div style={{ fontWeight: "bold" }}>{sgc.length} Items: </div>

          {sgc.length > 30
            ? null
            : sgc.map((object, i) => <div key={i}>{object.text}</div>)}
        </span>
      );
    };

    let dateTo = new Date();

    dateTo.setHours(0);
    dateTo.setMinutes(0);
    dateTo.setSeconds(0);

    const interval = [
      {
        id: 0,
        text: this.props.t("report.last3d"),
        command: () => {
          let dateFrom = utils.setOffsetDate(dateTo, 60 * 24 * 3);
          this.setState({
            intervalSelection: 0,
            dateTo: dateTo,
            dateFrom: dateFrom,
          });
        },
      },
      {
        id: 1,
        text: this.props.t("report.last7d"),
        command: () => {
          let dateFrom = utils.setOffsetDate(dateTo, 60 * 24 * 7);
          this.setState({
            intervalSelection: 1,
            dateTo: dateTo,
            dateFrom: dateFrom,
          });
        },
      },
      {
        id: 2,
        text: this.props.t("report.last31d"),
        command: () => {
          let dateFrom = utils.setOffsetDate(dateTo, 60 * 24 * 31);
          this.setState({
            intervalSelection: 2,
            dateTo: dateTo,
            dateFrom: dateFrom,
          });
        },
      },
      {
        id: 3,
        text: this.props.t("report.last3m"),
        command: () => {
          let dateFrom = utils.setOffsetDate(dateTo, 60 * 24 * 30 * 3);
          this.setState({
            intervalSelection: 3,
            dateTo: dateTo,
            dateFrom: dateFrom,
          });
        },
      },
      {
        id: 4,
        text: this.props.t("report.customInterval"),
        command: () => {
          this.setState({ intervalSelection: 4 });
        },
      },
      {
        id: 5,
        text: this.props.t("report.complete"),
        command: () => {
          dateTo.setDate(dateTo.getDate() + 1);
          let dateFrom = new Date("2019-01-01T00:00:00");
          this.setState({
            intervalSelection: 5,
            dateTo: dateTo,
            dateFrom: dateFrom,
          });
        },
        hidden: true,
      },
    ];

    const alertsNumber = !this.context.isSuperAdmin()
      ? utils.countAlertsForCustomer(
          this.props.alerts,
          this.context.activeOrganization.guid
        )
      : this.props.alerts.total;

    return (
      <Container fluid>
        <Card className="options-container mt--9">
          <ListDropdown
            toggleClass="fill-page"
            title={
              !this.state.logType
                ? this.props.t("alarmLog.activeAlarms")
                : this.props.t("alarmLog.advancedFilters")
            }
            listItems={[
              {
                text: this.props.t("alarmLog.activeAlarms"),
                command: async () => {
                  this.setState({ isLoading: true, logType: false, log: [] });
                  await this.getActiveAlarms(true);
                },
              },
              {
                text: this.props.t("alarmLog.advancedFilters"),
                command: () => {
                  this.setState({
                    logType: true,
                    log: [],
                    showFilters: true,
                  });
                },
              },
            ]}
          />
          {this.state.logType ? (
            <>
              <Button
                color="primary m-2"
                onClick={() => {
                  this.setState({ showFilters: true });
                }}
              >
                {this.props.t("alarmLog.advancedFilters")}
              </Button>
              {this.state.log.length > 0 ? (
                <div className="filter-info">
                  <span className="filter-time-container">
                    <span className="filter-time-from">
                      {this.props.t("alarmLog.from") + ": "}
                    </span>
                    <span className="filter-time">
                      {this.state.dateFrom.toLocaleString()}
                    </span>
                    <span className="filter-time-to">
                      {this.props.t("alarmLog.to") + ": "}
                    </span>
                    <span className="filter-time">
                      {this.state.dateTo.toLocaleString()}
                    </span>
                  </span>
                  <span className="filter-items">
                    <span className="filter-title">
                      {this.props.t("alarmLog.sites") + ": "}
                    </span>
                    <span id="sites">
                      {nSelectedSites === 0
                        ? this.props.t("alarmLog.all")
                        : nSelectedSites}
                      {"  "}
                      {keys.ICON_INFO}
                    </span>
                    <TooltipButton
                      target="sites"
                      text={<Info type="sites" number={nSelectedSites} />}
                      position="bottom"
                    />
                    <span className="filter-title">
                      {this.props.t("alarmLog.gateways") + ": "}
                    </span>
                    <span id="gateways">
                      {nSelectedGateways === 0
                        ? this.props.t("alarmLog.all")
                        : nSelectedGateways}
                      {"  "}
                      {keys.ICON_INFO}
                    </span>
                    <TooltipButton
                      target="gateways"
                      text={<Info type="gateways" number={nSelectedGateways} />}
                      position="bottom"
                    />
                    <span className="filter-title">
                      {this.props.t("alarmLog.controllers") + ": "}
                    </span>
                    <span id="controllers">
                      {nSelectedControllers === 0
                        ? this.props.t("alarmLog.all")
                        : nSelectedControllers}
                      {"  "}
                      {keys.ICON_INFO}
                    </span>
                    <TooltipButton
                      target="controllers"
                      text={
                        <Info
                          type="controllers"
                          number={nSelectedControllers}
                        />
                      }
                      position="bottom"
                    />
                  </span>
                </div>
              ) : null}
            </>
          ) : (
            <div className="live-alarms-on">
              <span className="live-alarms-on-label">
                {this.props.t("alarmLog.activeAlarms") + ": "}
              </span>
              <span>{alertsNumber < 0 ? "..." : alertsNumber}</span>
            </div>
          )}

          <div className="table-container">
            <Table id="log-table" responsive bordered hover striped size="sm">
              <thead className="thead-light">
                <tr>
                  {HEADER_TABLE.map((header, i) => (
                    <th key={i} onClick={() => this.sortTable(header, i)}>
                      <span className="col-header">
                        <span>{this.props.t("alarmLog." + header)}</span>{" "}
                        <span>{utils.getSortIcon(this.state.sortIcon[i])}</span>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!this.state.isLoading && this.state.log.length === 0 ? (
                  <tr>
                    <td className="no-alarms" colSpan={HEADER_TABLE.length}>
                      {this.props.t("alarmLog.noAlarms")}
                    </td>
                  </tr>
                ) : (
                  this.state.log.map((item, i) => {
                    const redirect = async () => {
                      this.setState({ isLoading: true });

                      const controllers = await api.getDevicesOnSite(
                        item.siteGuid
                      );

                      this.props.setControllers(controllers);

                      const device = controllers.byId[item.deviceGuid];

                      const site = this.props.sites.byId[device.site.siteGuid];

                      this.siteInfo = {
                        siteGuid: device.site.siteGuid,
                        gatewayGuid: device.gateway.gatewayGuid,
                        deviceGuid: device.deviceGuid,
                        name: device.model,
                        address: device.address,
                        siteInfo: {
                          siteGuid: device.site.siteGuid,
                          name: site.name,
                          city: site.city,
                          country: site.country,
                          connectivitytype: site.connectivitytype,
                          latitude: site.latitude,
                          longitude: site.longitude,
                        },
                      };

                      this.setState({ redirect: true });
                    };

                    let { minValue, maxValue, rangePercentage } =
                      this.allThresholdsList[item.thresholdGuid];

                    let bit = utils.getThresholdType(
                      maxValue,
                      minValue,
                      rangePercentage
                    );

                    const site = this.props.sites.byId[item.siteGuid];

                    return (
                      <tr
                        key={i}
                        className={
                          item.end === this.props.t("alarmLog.alarmOn")
                            ? "table-danger"
                            : ""
                        }
                        onClick={() => redirect()}
                      >
                        <td>{site.name}</td>
                        <td>
                          {(item.friendlyName === undefined ||
                          item.friendlyName === null ||
                          item.friendlyName === ""
                            ? item.model
                            : item.friendlyName) +
                            " (" +
                            item.gateway.name +
                            ")"}
                        </td>
                        <td>{item.label}</td>
                        <td>
                          {bit > 0
                            ? item.value === 1
                              ? "ON"
                              : "OFF"
                            : item.value}
                        </td>
                        <td>
                          {bit === 0
                            ? `${item.value > item.thresholdValue ? "≥" : "≤"} ${item.thresholdValue}`
                            : bit === 1
                              ? this.props.t(
                                  "deviceThresholds.activeOnRisingEdge"
                                )
                              : bit === 2
                                ? this.props.t(
                                    "deviceThresholds.activeOnFallingEdge"
                                  )
                                : ""}
                        </td>
                        <td>{item.start}</td>
                        <td>{item.end}</td>
                        <td>{item.duration}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
            {this.state.isLoading ? <Loader /> : null}
          </div>

          <Modal
            centered
            isOpen={this.state.showFilters}
            toggle={this.toggleFilterModal}
          >
            <ModalBody>
              <div className="filter-container">
                <div className="filter-options-container">
                  <span className="filter-text">
                    {this.props.t("report.interval") + ": "}
                  </span>
                  <ListDropdown
                    className="filter-list"
                    title={
                      interval.filter(
                        (x) => x.id === this.state.intervalSelection
                      )[0].text
                    }
                    noToggle
                    listItems={interval}
                  />
                  <span className="filter-text">
                    {this.props.t("alarmLog.from") + ": "}
                  </span>
                  <span>
                    <input
                      className="input-date-log"
                      type="date"
                      value={utils.formatDay(this.state.dateFrom)}
                      onChange={(e) => {
                        const { value } = e.target;
                        let { dateFrom } = this.state;

                        dateFrom.setFullYear(new Date(value).getFullYear());
                        dateFrom.setMonth(new Date(value).getMonth());
                        dateFrom.setDate(new Date(value).getDate());

                        this.setState({ dateFrom });
                      }}
                      disabled={this.state.intervalSelection !== 4}
                    />
                    <input
                      className="input-time-log"
                      type="time"
                      step={1}
                      value={this.state.dateFrom.toLocaleTimeString()}
                      onChange={(e) => {
                        let values = e.target.value.split(":");
                        let { dateFrom } = this.state;

                        if (values.length === 2) values.push("00");
                        dateFrom.setHours(parseInt(values[0]));
                        dateFrom.setMinutes(parseInt(values[1]));
                        dateFrom.setSeconds(parseInt(values[2]));

                        this.setState({ dateFrom });
                      }}
                      disabled={this.state.intervalSelection !== 4}
                    />
                  </span>

                  <span className="filter-text">
                    {this.props.t("alarmLog.to") + ": "}
                  </span>
                  <span>
                    <input
                      className="input-date-log"
                      type="date"
                      value={utils.formatDay(this.state.dateTo)}
                      onChange={(e) => {
                        const { value } = e.target;
                        let { dateTo } = this.state;

                        dateTo.setFullYear(new Date(value).getFullYear());
                        dateTo.setMonth(new Date(value).getMonth());
                        dateTo.setDate(new Date(value).getDate());

                        this.setState({ dateTo });
                      }}
                      disabled={this.state.intervalSelection !== 4}
                    />
                    <input
                      className="input-time-log"
                      type="time"
                      step={1}
                      value={this.state.dateTo.toLocaleTimeString()}
                      onChange={(e) => {
                        let values = e.target.value.split(":");
                        let { dateTo } = this.state;

                        if (values.length === 2) values.push("00");
                        dateTo.setHours(parseInt(values[0]));
                        dateTo.setMinutes(parseInt(values[1]));
                        dateTo.setSeconds(parseInt(values[2]));

                        this.setState({ dateTo });
                      }}
                      disabled={this.state.intervalSelection !== 4}
                    />
                  </span>

                  {/* Sites */}
                  <span className="filter-text">
                    {this.props.t("alarmLog.sites") + ": "}
                  </span>
                  <ListDropdown
                    className="filter-list"
                    title={
                      this.props.t("alarmLog.sites") +
                      " (" +
                      (nSelectedSites === 0
                        ? this.props.t("alarmLog.all").toString().toLowerCase()
                        : nSelectedSites) +
                      " " +
                      this.props.t("alarmLog.selected") +
                      ")"
                    }
                    noToggle
                    listItems={this.state.selectedSites}
                  />
                  {/* Gateways */}
                  <span className="filter-text">
                    {this.props.t("alarmLog.gateways") + ": "}
                  </span>
                  <ListDropdown
                    className="filter-list"
                    title={
                      this.props.t("alarmLog.gateways") +
                      " (" +
                      (nSelectedGateways === 0
                        ? this.props.t("alarmLog.all").toString().toLowerCase()
                        : nSelectedGateways) +
                      " " +
                      this.props.t("alarmLog.selected") +
                      ")"
                    }
                    noToggle
                    listItems={this.state.selectedGateways}
                  />
                  {/* Controllers */}
                  <span className="filter-text">
                    {this.props.t("alarmLog.controllers") + ": "}
                  </span>
                  <ListDropdown
                    className="filter-list"
                    title={
                      this.props.t("alarmLog.controllers") +
                      " (" +
                      (nSelectedControllers === 0
                        ? this.props.t("alarmLog.all").toString().toLowerCase()
                        : nSelectedControllers) +
                      " " +
                      this.props.t("alarmLog.selected") +
                      ")"
                    }
                    noToggle
                    listItems={this.state.selectedControllers}
                  />
                </div>
                <Button
                  className="btn-apply-filter"
                  color="primary"
                  onClick={() => {
                    this.getAlertsLog();
                    this.toggleFilterModal();
                  }}
                >
                  {this.props.t("alarmLog.apply")}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Card>
      </Container>
    );
  }
}

class Alerts extends Component {
  render() {
    return this.props.alerts.total === -1 ? (
      <Loader />
    ) : (
      <AlarmLog {...this.props} />
    );
  }
}

const getGateways = createSelector(
  (state) => state.gateways,
  (gateways) => {
    return gateways;
  }
);

const getSites = createSelector(
  (state) => state.sites,
  (sites) => {
    return sites;
  }
);

const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => {
    return alerts;
  }
);

const mapStateToProps = (state) => {
  return {
    gateways: getGateways(state),
    sites: getSites(state),
    alerts: getAlerts(state),
    controllers: state.controllers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setControllers,
      resetControllers,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Alerts)
);
