import React from 'react';
import build from '../configs/commit.json';
import Logo from 'assets/img/brand/Corp_RGB_Standard_Digital.png';

const SIGNALR_HUB_URL = 
  process.env.REACT_APP_STREAM_HUB_URL ||
  'https://xp-apimgmt-dev.azure-api.net/realtime/stream/telemetry';
const API_URL =
  process.env.REACT_APP_API_URL || 'https://xp-apimgmt-dev.azure-api.net/iot-api';

const MOBILE_API_URL =
  process.env.REACT_APP_MOBILE_API_URL || 'https://xp-apimgmt-dev.azure-api.net/mapp/api';

const CORE_API_URL = 
    process.env.REACT_APP_CORE_API_URL || 'https://xp-apimgmt-dev.azure-api.net/iam/api';

const XP_REPORTING_API_URL = process.env.REACT_APP_XP_REPORTING_API_URL || 'https://xpreportfunctiondev.azurewebsites.net';

// TODO: This is a temporary solution to provide the quick installation guide. In future, we will provide this file using a more secure and reliable way.
const XP_QUICK_INSTALLATION_GUIDE_IPL600_URL = 'https://xpadb2ccustompagesprod.blob.core.windows.net/docs/IPL600D CLOUD_DAMA QRG EN 2024.pdf';
const XP_QUICK_INSTALLATION_GUIDE_XIG100_URL = 'https://xpadb2ccustompagesprod.blob.core.windows.net/docs/QRG-XIG100.pdf';

// This is passed in the headers as `Ocp-Apim-Subscription-Key` for api management.
// At the moment it is not really required in dev environment
const API_KEY = process.env.REACT_APP_API_KEY || '64bf4b3236534e5d9f437863c45bc5d1';

const IS_PROD = API_URL.includes('prod') ? true : false;

const B2C_APPID = IS_PROD
  ? '0146b541-12e4-429d-992e-1bd243b3224d' // Production
  : 'e8cf1d81-ca9f-468d-a2a7-2367c1a24764' // Dev and Local

const B2C_TENANT = IS_PROD ? 'xploreprod' : 'xploredev';
const B2C_SCOPE = IS_PROD
  ? 'https://xploreprod.onmicrosoft.com/5879dc40-283c-42aa-a0e5-f49a4a39d4ce/xpapi'
  : 'https://xploredev.onmicrosoft.com/9adf1a19-79cb-478a-991c-401c19e84d15/xpapi';

const MAX_MEASURE_INTERVAL_IN_DAYS = 31;
const constants = {
  SIGNALR_HUB_URL,
  API_URL,
  MOBILE_API_URL,
  CORE_API_URL,
  XP_REPORTING_API_URL,
  XP_QUICK_INSTALLATION_GUIDE_IPL600_URL,
  XP_QUICK_INSTALLATION_GUIDE_XIG100_URL,
  API_KEY,
  IS_PROD,
  B2C_APPID,
  B2C_TENANT,
  B2C_SCOPE,
  B2C_SIGNUP_SIGNIN_POLICY: 'B2C_1A_SignupSignin',
  B2C_PASSWORD_RESET_POLICY: 'B2C_1A_PasswordReset',
  B2C_PROFILE_EDIT: 'B2C_1A_ProfileEdit',
  B2C_LOGIN_MODE: 'REDIRECT',
  SHOW_CREATIVETIM_DOC: process.env.REACT_APP_SHOW_DOC || 'no',
  FRONTEND_VERSION: build.commitID.substring(0, 7),
  LOGO_URL: Logo,
  PRODUCT_NAME1: 'Foodservice',
  PRODUCT_NAME2: 'Data Management Cloud',
  ICON_DEVICE_DESCRIPTOR: <i className=" ni ni-books" />,
  ICON_MENU_DEVICE_DESCRIPTOR: <i className=" ni ni-books text-primary " />,
  ICON_KPI: <i className="fas fa-vr-cardboard" />,
  ICON_GATEWAY: <i className=" ni ni-app " />,
  ICON_MENU_GATEWAY: <i className=" ni ni-app text-primary " />,
  ICON_CONTROLLER: <i className="fas fa-hdd" />, //<i className=" ni ni-spaceship " />,
  ICON_SETTINGS: <i className=" ni ni-settings " />,
  ICON_MAP: <i className=" ni ni-world " />,
  ICON_SITE: <i className=" ni ni-shop " />,
  ICON_SEARCH: <i className=" fas fa-search " />,
  ICON_LOGOUT: <i className=" ni ni-user-run " />,
  ICON_USERPROFILE: <i className=" ni ni-single-02 " />,
  ICON_MENU_USERPROFILE: <i className=" ni ni-single-02 text-primary " />,
  ICON_CUSTOMER: <i className=" fa-solid fa-sitemap" />,
  ICON_SMILE: <i className="fas fa-grin " />,
  ICON_MENU_CUSTOMER: <i className=" ni ni-diamond text-primary " />,
  ICON_MAP_MARKER_WIFI: <i className=" fa fa-wifi fa-2x " />,
  ICON_MAP_MARKER_ETH: <i className=" fa fa-wifi fa-2x " />,
  ICON_MAP_MARKER_MODEM: <i className=" fa fa-plug fa-2x " />,
  ICON_MAP_MARKER_DEFAULT: <i className=" fa fa-map-marker-alt fa-2x " />,
  ICON_MAP_MARKER_EMPTY_SITE: <i className="fa fa-2x fa-times" />,
  ICON_SITE_MAP: <i className=" fa fa-map-marker-alt " />,
  ICON_SITE_CONTROLLER: <i className="fas fa-hdd" />,
  ICON_MENU_DASHBOARD: <i className=" ni ni-tv-2 text-primary " />,
  ICON_MENU_SITE: <i className=" ni ni-shop text-primary " />,
  ICON_MENU_CONTROLLER: <i className="fas fa-hdd text-primary" />, //<i className=" ni ni-spaceship text-primary " />,
  ICON_MENU_ADD_GATEWAY: <i className=" fas fa-plus-circle text-primary " />,
  ICON_MENU_ALARM_SETTINGS: <i className=" ni ni-bell-55 text-primary " />,
  ICON_MENU_VISIBILITY: <i className=" ni ni-square-pin text-primary " />,
  ICON_MENU_SUPPORT: <i className=" ni ni-support-16 text-primary " />,
  ICON_MENU_DEL_ENTITIES: <i className="far fa-calendar-times text-primary" />,
  ICON_MENU_LICENSE: <i className="fa fa-key text-primary" />,
  ICON_MENU_ADD: <i className=" fas fa-plus-circle text-primary" />,
  ICON_QUESTION_CIRCLE: <i className=" far fa-question-circle text-primary" />,
  ICON_CONTROLLER_ALLGOOD: <i className=" fas fa-smile " />,
  ICON_CONTROLLER_ALARM: <i className=" ni ni-bell-55 " />,
  ICON_DASHBOARD_ALARM: <i className=" ni ni-bell-55 " />,
  ICON_CONTROLLER_ADVICE: <i className="ni ni-air-baloon " />,
  ICON_WEL_ALLGOOD: <i className=" fas fa-smile " />,
  ICON_WEL_BAD: <i className=" ni ni-bell-55 " />,
  ICON_CHART: <i className="ni ni-chart-bar-32" />,
  ICON_SAVE: <i className=" fas fa-save fa-lg " />,
  ICON_UNDO: <i className=" fas fa-undo-alt fa-lg " />,
  ICON_SEND_COMMAND: <i className=" fas fa-paper-plane " />,
  ICON_EDIT: <i className=" fas fa-edit " />,
  ICON_ERROR: <i className=" fas fa-exclamation " />,
  ICON_OK: <i className=" far fa-check-circle " />,
  ICON_LOAD: <i className="fas fa-sync-alt" />,
  ICON_REPORTS: <i className="fa fa-table " />,
  ICON_RECEIVERS: <i className=" fas fa-paper-plane " />,
  ICON_VIRTUAL_ALARM: <i className=" fas fa-cloud " />,
  ICON_MAX: <i className=" fas fa-temperature-high " />,
  ICON_MIN: <i className=" fas fa-temperature-low " />,
  ICON_PERCENT: <i className=" fas fa-percent " />,
  ICON_TRASH: <i className=" far fa-trash-alt " />,
  ICON_ADD: <i className=" fas fa-plus-circle " />,
  ICON_ANALOG: <i className="fas fa-chart-line " />,
  ICON_HDD: <i className="fas fa-hdd " />,
  ICON_MENU_ALARM_LOG: <i className="fas fa-exclamation-circle text-primary" />,
  ICON_INFO: <i className="fa fa-info-circle" aria-hidden="true" />,
  ICON_SORT: <i className="fa fa-sort" aria-hidden="true" />,
  ICON_SORT_ASC: <i className="fa fa-sort-up" aria-hidden="true" />,
  ICON_SORT_DESC: <i className="fa fa-sort-down" aria-hidden="true" />,
  ICON_ARROW_LEFT: <i className="fa fa-arrow-left" aria-hidden="true" />,
  ICON_WARNING: <i className="fa fa-exclamation-triangle" aria-hidden="true" />,
  ICON_DATE: <i className="fa fa-calendar" aria-hidden="true" />,
  ICON_SIDEBAR_MENU: <i className="fa fa-bars" aria-hidden="true" />,
  ICON_ENTITY_OWNER: <i className="fa fa-key" aria-hidden="true" />,
  ICON_ENTITY_VIEWER: <i className="fa fa-eye" aria-hidden="true" />,
  ICON_PREFERENCES: <i className="fas fa-pencil-ruler" />,
  ICON_LANGUAGES: <i className="fas fa-globe-europe" />,
  ICON_CLOSE: <i className="fas fa-times" />,
  ICON_LICENSE: <i className="fa fa-key" />,
  ICON_DURATION: <i className="fas fa-clock" />,
  ICON_COPY: <i className="fas fa-copy" />,
  ICON_LICENSE_DEFERRED: <i className="fas fa-arrow-right" />, // <i className="fas fa-crystal-ball"/>
  ICON_EYE_PASSWORD: <i className="fa fa-eye" aria-hidden="true" />,
  ICON_EYE_PASSWORD_SLASH: <i className="fa fa-eye-slash" aria-hidden="true" />,
  ICON_PAPER_PLANE: <i className="far fa-paper-plane" />,
  ICON_USER: <i className="fa fa-user" />,
  ICON_ADD_USER: <i className="fa-solid fa-user-plus"/>,
  ICON_CALENDAR: <i className="fa-regular fa-calendar-days"/>,
  ICON_SERIAL_ID: <i className="fa-solid fa-bars-staggered"/>,
  MAX_MEASURE_INTERVAL_IN_DAYS,
};

export default constants;
