import React from "react";
import DeviceParameters from "./DeviceParameters";
import DongleParameters from "./DongleParameters";

const Parameters = ({
  isDongleController,
  name,
  address,
  deviceGuid,
  gatewayInfo,
  ...props
}) => {
  return isDongleController ? (
    <DongleParameters gatewayInfo={gatewayInfo} {...props} />
  ) : (
    <DeviceParameters
      name={name}
      address={address}
      deviceGuid={deviceGuid}
      gatewayInfo={gatewayInfo}
      {...props}
    />
  );
};

export default Parameters;
