import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Input,
  InputGroupText,
  Button,
  ButtonGroup,
} from "reactstrap";

import geoLocation from "services/geoLocation";
import { connect } from "react-redux";
import {
  updateSite,
  apiAction,
  setControllers,
  setGateways,
  setSites,
} from "redux/actions/index";
import { bindActionCreators } from "redux";

import OpenStreetMap from "components/OpenStreetMap";
import GatewayTable from "components/admin/GatewayTable";
import Loader from "components/Loader";
import { TooltipWarning } from "../../components/Tooltip";

import api from "services/backendService";
import keys from "configs/constants";

import utils from "utils";
import { withTranslation } from "react-i18next";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import "./siteProperties.css";
import RegisterNewGateway from "components/RegisterNewGateway/index";

class SiteProperties extends React.Component {
  static contextType = AuthorizationContext;

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.location.site.name,
      country: this.props.location.site.country,
      city: this.props.location.site.city,
      latitude: this.props.location.site.latitude,
      longitude: this.props.location.site.longitude,
      siteGuid: this.props.location.site.siteGuid,
      address: "",
      positionChanged: false,
      nameChanged: false,
      inputError: {
        nameError: false,
      },
      isLoading: true,
      gateways: this.props.store.gateways,
      showNewGatewayModal: false,
      newMacAddress: "",
    };

    this.name = this.state.name;
  }

  updateMap(pos) {
    var t = this;
    geoLocation.getLocationFromCoordinates(pos.lat, pos.lng).then((results) => {
      if (results.status !== 200) return;
      const r = results.data.address;
      utils.debug("Reverse Geocoding address data: " + JSON.stringify(r));
      t.setState({
        latitude: pos.lat,
        longitude: pos.lng,
        address:
          (r.road || "-") +
          ", " +
          (r.city || r.town || r.suburb || r.village || "-") +
          ", " +
          (r.county || "-") +
          ", " +
          (r.state || "-") +
          ", " +
          (r.country || "-"),
        country: r.country,
        city: r.city || r.town || r.suburb || r.village || "-",
      });
    });
  }

  componentDidUpdate = async (prevProps) => {
    let { gateways } = this.state;
    let update = false;

    for (let gw of this.props.store.gateways) {
      let prevGw = prevProps.store.gateways.filter(
        (g) => g.gateway.gatewayGuid === gw.gateway.gatewayGuid
      )[0];
      if (
        gw.gateway.status !== prevGw.gateway.status ||
        JSON.stringify(gw.gateway.lastStatus) !==
          JSON.stringify(prevGw.gateway.lastStatus)
      ) {
        // GW has changed its state, update data
        update = true;
        for (let g of gateways) {
          if (g.gateway.gatewayGuid === gw.gateway.gatewayGuid) {
            g.gateway.status = gw.gateway.status;
            g.gateway.releaseFW = gw.gateway.releaseFW ?? g.gateway.releaseFW;
            g.gateway.lastStatus = gw.gateway.lastStatus;
            break;
          }
        }
      }
    }

    if (update) this.setState({ gateways });
  };

  componentDidMount = async () => {
    let { gateways } = this.state;

    for (let gw of gateways) {
      if (!gw.gateway.releaseFW) {
        gw.gateway.releaseFW = await utils.getGatewayRelease(
          gw.gateway.gatewayGuid
        );
      }

      // TODO: replace role with boolean (rw or ro)
      gw.gateway.level = this.context.hasWritePermission(gw.gateway.gatewayGuid)
        ? "Owner"
        : "Viewer";
    }

    this.setState({ gateways, isLoading: false });

    this.updateMap({ lat: this.state.latitude, lng: this.state.longitude });
  };

  onInputChange(evt) {
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    if (name === "name") {
      if (value !== this.name)
        this.setState({ [name]: value, nameChanged: true });
      else this.setState({ [name]: value, nameChanged: false });
      let { inputError } = this.state;
      if (value === "") inputError.nameError = true;
      else inputError.nameError = false;
      this.setState({ inputError });
    }
    this.setState({ [name]: value });
  }

  onDragEnd(evt) {
    this.updateMap(evt);
    this.setState({ positionChanged: true });
  }

  onForgetChangesClicked() {
    const oLat = this.props.location.site.latitude;
    const oLng = this.props.location.site.longitude;
    const oName = this.name;
    this.updateMap({ lat: oLat, lng: oLng });
    this.setState({
      latitude: oLat,
      longitude: oLng,
      name: oName,
      positionChanged: false,
      nameChanged: false,
      inputError: {
        nameError: false,
      },
    });
  }

  onSaveChangesClicked = async () => {
    const customerGuid = this.context.isSuperAdmin()
      ? null
      : this.context.activeOrganization.guid;
    
    this.props.apiAction(
      api.updateSite,
      [
        this.state.name,
        this.state.country,
        this.state.city,
        this.state.latitude,
        this.state.longitude,
        this.props.location.site.siteGuid,
      ],
      updateSite
    );

    this.setState({ isLoading: true });

    this.props.setControllers(
      await api.getDevicesOnSite(this.props.location.site.siteGuid)
    );
    this.props.setSites(
      await api.getSiteInitialState(null, false, customerGuid)
    );
    this.props.setGateways(
      await api.getGatewayInitialState(customerGuid,null, null, false)
    );

    this.name = this.state.name;

    this.setState({
      nameChanged: false,
      positionChanged: false,
      isLoading: false,
    });
  };

  closeModal = () => {
    this.setState({
      newMacAddress: "",
      showNewGatewayModal: false,
    });
  };

  render() {
    if (this.props.store.apiFetching || this.state.isLoading) return <Loader />;

    const center = [this.state.latitude, this.state.longitude];
    const somethingChanged =
      this.state.nameChanged || this.state.positionChanged;

    const inputError = this.state.inputError.nameError;

    const readOnly = !this.context.hasWritePermission(this.state.siteGuid);

    return (
      <>
        <RegisterNewGateway
          isOpen={this.state.showNewGatewayModal}
          toggle={this.closeModal}
          value={this.state.newMacAddress}
          onChange={(e) => {
            this.setState({ newMacAddress: e.target.value });
          }}
          siteGuid={this.props.location.site.siteGuid}
        />
        <Container fluid>
          <Card className="card-profile shadow">
            <CardHeader className="text-center">
              <div className="h2">{this.name}</div>
              <Button
                className="float-right site-add-gateway"
                color="primary"
                size="sm"
                onClick={() => {
                  this.setState({
                    showNewGatewayModal: true,
                  });
                }}
              >
                <span>{this.props.t("sidebar.registerNewGateway")}</span>
              </Button>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupText
                      id="group-name"
                      className={
                        this.state.inputError.nameError
                          ? "bg-danger text-white"
                          : ""
                      }
                    >
                      {keys.ICON_SITE}
                      <TooltipWarning
                        target="group-name"
                        text={this.props.t("siteProperties.nameVoid")}
                        warning={this.state.inputError.nameError}
                      />
                    </InputGroupText>
                    <Input
                      placeholder={this.props.t("siteProperties.name")}
                      id="name"
                      name="name"
                      type="text"
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.name}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <div
                className="mb-2"
                style={{
                  height: "350px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <OpenStreetMap
                  markers={[this.state]}
                  center={center}
                  zoom={16}
                  noHQ
                  onDragEnd={this.onDragEnd.bind(this)}
                />
              </div>
              <Form>
                <FormGroup id="site-form">
                  <InputGroup>
                    <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                    <Input
                      placeholder={this.props.t("siteProperties.address")}
                      name="address"
                      type="text"
                      value={this.state.address}
                      readOnly
                    />
                  </InputGroup>
                  <ButtonGroup id="site-form-buttons">
                    <Button
                      className="btn-icon"
                      color="secondary"
                      type="button"
                      onClick={this.onForgetChangesClicked.bind(this)}
                      disabled={!somethingChanged || readOnly}
                    >
                      <span>{keys.ICON_UNDO}</span>
                      <span className="ml-2 site-form-btn-text">
                        {this.props.t("siteProperties.forget")}
                      </span>
                    </Button>
                    <Button
                      className="btn-icon"
                      color="primary"
                      type="button"
                      onClick={this.onSaveChangesClicked}
                      disabled={!somethingChanged || inputError || readOnly}
                    >
                      <span>{keys.ICON_SAVE}</span>
                      <span className="ml-2 site-form-btn-text">
                        {this.props.t("siteProperties.save")}
                      </span>
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Form>
              <GatewayTable
                userData={this.state.gateways}
                site={this.props.location.site}
                disabled={readOnly}
              />
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

const gatewaysForThisSite = (state, sGuid) => {
  let gateways = [];

  const storeGateways = Object.values(state.gateways.byId);

  for (const g of storeGateways) {
    if (g.site.siteGuid === sGuid) {
      let gw = JSON.parse(JSON.stringify(g));
      gateways.push({ gateway: gw });
    }
  }
  return gateways;
};

const mapStateToProps = (state, ownProps) => {
  const { siteGuid } = ownProps.location.site;
  return {
    store: {
      gateways: gatewaysForThisSite(state, siteGuid),
      apiFetching: state.api.isFetching,
      apiError: state.api.error,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
      setControllers,
      setGateways,
      setSites,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(SiteProperties)
);
