import React from "react";

const IconLabel = ({ icon, label, minWidth = "auto" }) => (
  <div className="d-flex align-items-center">
    {icon}
    <span 
        className="ml-2 text-truncate d-none d-lg-block text-left" 
        style={{minWidth}}>
            {label}
    </span>
  </div>
);

export default IconLabel;
