import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import Tabs from "../components/Tabs/Tabs";
import DeviceCards from "../components/DeviceCards";
import Parameters from "../components/Parameters";
import Chart from "../components/Chart/index";
import DeviceInfo from "../components/DeviceInfo/DeviceInfo";
import { updateCurrentPage } from "../redux/actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import utils from "../utils";
import api from "../services/backendService";
import Loader from "components/Loader";

class SingleView extends React.Component {
  constructor(props) {
    super(props);

    this.props.updateCurrentPage(this.props.location);

    const gatewayGuid = this.props.gatewayInfo
      ? this.props.gatewayInfo.gatewayGuid 
      : this.props.location.site.gatewayGuid;

    api.refreshVars(gatewayGuid);
  }

  render() {
    const { deviceGuid, address, siteInfo, friendlyName, isDongleController } = this.props;
    let { gatewayInfo } = this.props;
    gatewayInfo = gatewayInfo ?? this.props.location.gatewayInfo;
    
    if (!gatewayInfo) {
      return <Loader />
    }

    return (
      <>
        <Container fluid className="mt--9 mb-4">
          <Tabs
            title={friendlyName}
            address={!isDongleController ? address : undefined}
            siteInfo={siteInfo}
            gatewayInfo={gatewayInfo}
          >
            <DeviceCards
              key={1}
              name="variables"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />

            <Parameters
              key={2}
              name="parameters"
              address={address}
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
              isDongleController={isDongleController}
            />

            <Chart
              key={3}
              name="chart"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />

            <DeviceInfo
              key={4}
              name="details"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
              isDongleController={isDongleController}
              cabinetSerial={this.props.cabinetSerial}
            />
          </Tabs>
        </Container>
      </>
    );
  }
}

const getCachedDevice = () => {
  return JSON.parse(window.sessionStorage.getItem("li"));
};

const getGatewayInfo = (gatewayStore, deviceGuid) => {
  for (let gGuid of gatewayStore.allIds) {
    for (let device of gatewayStore.byId[gGuid].devices) {
      if (device.deviceGuid === deviceGuid) {
        return gatewayStore.byId[gGuid];
      }
    }
  }
  return undefined;
};

const getControllerName = (controller, model) => {
  return controller.friendlyName === null || controller.friendlyName === ""
  ? utils.getModelFromMFVE(model)
  : controller.friendlyName;
}

const mapStateToProps = (state, ownProps) => {
  const lastDevice = getCachedDevice();
  const deviceGuid = ownProps.location.site.deviceGuid ?? lastDevice.deviceGuid;
  const address = ownProps.location.site.address ?? lastDevice.address;
  const siteInfo = ownProps.location.site.siteInfo ?? lastDevice.siteInfo;
  return {
    deviceGuid,
    address,
    siteInfo,
    gatewayInfo: getGatewayInfo(state.gateways, deviceGuid),
    friendlyName: getControllerName(state.controllers.byId[deviceGuid] ?? {friendlyName: null}, ownProps.location.site.name),
    isDongleController: state.controllers.byId[deviceGuid]?.isDongleController ?? false,
    cabinetSerial: state.controllers.byId[deviceGuid]?.cabinetSerial ?? "",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleView);
