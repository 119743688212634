import React, {useState} from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./organizationDropdown.css";
import keys from "../configs/constants";

const OrganizationDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  let { listItems, stopPropagation } = props;
  const toggle = (e) => {
    if (stopPropagation) {
        e.stopPropagation();
    }
    setDropdownOpen((prevState) => !prevState);
  };
  
  return (
    <Dropdown 
        className="org-dropdown-container"
        isOpen={dropdownOpen}
        toggle={toggle}
    >
        <DropdownToggle className= "org-dropdown-toggle" tag="span" caret>
            <span className={`org-toggle-icon mx-2 ${props.iconColor}`}>
                {keys.ICON_CUSTOMER}
            </span>
            <span className="org-dropdown-toggle-title ml-2 text-dark" title={props.title} style={{ color: props.iconColor }} >
                {props.title}
            </span>
        </DropdownToggle>
        <DropdownMenu className="org-dropdown-menu border">
          {listItems.map((item, i) => {
            return (
              <DropdownItem
                key={i}
                className = "org-dropdown-item-content"
                onClick={item.command}
              >
                <span className="org-dropdown-item-text m-0 p-0"  title={item.text}>
                    {item.text}
                </span>
                {item.isAlert && (
                    <span className="org-item-icon text-warning">
                      {keys.ICON_WARNING}
                    </span>
                )}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
    </Dropdown>
  );
};

export default OrganizationDropdown;
